// // src/hooks/useFetch.js

import { useState, useEffect } from "react";

const useFetch = (url, options = {}, dependency = "") => {
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Start loading
        setLoading(true); // Start loading
        setIsError(null); // Reset error state before fetching

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
          // Success status (2xx)
          setApiData(result); // Set API data
        } else {
          const errorMessage = result.error || "Something went wrong";
          throw new Error(errorMessage); // Trigger error
        }
      } catch (error) {
        setIsError(error.message); // Set error message
      } finally {
        setIsLoading(false); // Stop loading
        setLoading(false); // Stop loading
      }
    };

    fetchData();
    console.log("Fetching data from:", url);
  }, [url, dependency]);

  return { apiData, isLoading, isError, loading }; // Return data, loading, and error states
};

export default useFetch;
