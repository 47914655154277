import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";
import {
  fetchEmployees,
  deleteEmployee,
  updateEmployee,
} from "../../../store/slices/EmployeeSlice";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { Avatar_02 } from "../../../Routes/ImagePath";

const EmployeeList = () => {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state?.employee?.employees);
  const role = useSelector((state) => state?.auth?.userRole);
  const token = useSelector((state) => state?.auth?.userInfo?.userToken);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);
  console.log("employees table LLLLLLLLLLLLLLL", employees);
  useEffect(() => {
    setFilteredEmployees(employees); // Initialize with full list
  }, [employees]);

  const handleFilterChange = ({ employeeId, employeeName, department }) => {
    const filtered = employees.filter((employee) => {
      const matchesId = employeeId
        ? employee.employeeId.includes(employeeId)
        : true;
      const matchesName = employeeName
        ? employee.name.toLowerCase().includes(employeeName.toLowerCase())
        : true;
      const matchesDepartment = department
        ? employee?.department &&
          employee?.department?._id === department?.value
        : true;

      return matchesId && matchesName && matchesDepartment;
    });
    setFilteredEmployees(filtered);
  };

  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    if (employeeToDelete) {
      dispatch(deleteEmployee(employeeToDelete._id));
    }
    setShowDeleteModal(false);
    setEmployeeToDelete(null);
  };

  const handleStatusChange = (employee, status) => {
    dispatch(updateEmployee({ id: employee._id, status })); // Update the status
  };
  const handleRoleChange = async (roleName, userId) => {
    console.log(roleName, `form data in handle submit`, userId);
    const data = new FormData();
    data.append("role", roleName);

    console.log(data, `data before submission`);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.patch(
        `https://zolara-crm-backend.vercel.app/api/users/${userId}`,
        data,
        config
      );

      if (response.status === 200) {
        alert(`updated successfully!`);
        dispatch(fetchEmployees());
      }
    } catch (error) {
      console.error(
        `Error updating`,
        error.response ? error.response.data : error.message
      );
      alert(`Failed to update. Please try again.`);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to={`/profile/${record._id}`} className="avatar">
            <img alt="" src={record.image || Avatar_02} />
          </Link>
          <Link to={`/profile/${record._id}`}>
            {text}{" "}
            <span>
              {record.lastName} ( {record.role} )
            </span>
          </Link>
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      sorter: (a, b) => a.employeeId.length - b.employeeId.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Mobile",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Join Date",
      dataIndex: "dateOfJoining",
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.dateOfJoining) - new Date(b.dateOfJoining),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text, record) => (
        <div className="dropdown action-label text-start">
          <a
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "admin"
                  ? "far fa-dot-circle text-purple"
                  : text === "employee"
                  ? "far fa-dot-circle text-info"
                  : text === "manager"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </a>
          {role === "admin" ? (
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                onClick={() => handleRoleChange("admin", record._id)}
              >
                <i className="far fa-dot-circle text-success" /> Admin
              </a>
              <a
                className="dropdown-item"
                onClick={() => handleRoleChange("employee", record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> Employee
              </a>
              <a
                className="dropdown-item"
                onClick={() => handleRoleChange("manager", record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> Manager
              </a>
              <a
                className="dropdown-item"
                onClick={() => handleRoleChange("hr", record._id)}

                // onClick={() => handleRejectLeave(record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> HR
              </a>
            </div>
          ) : (
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                // onClick={() => handleApproveLeave(record._id)}
              >
                {""}
              </a>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="dropdown profile-action">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {record.status !== "active" && (
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => handleStatusChange(record, "active")}
              >
                <i className="fa fa-check m-r-5" />
                Enable
              </Link>
            )}
            {record.status !== "disabled" && (
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => handleStatusChange(record, "disabled")}
              >
                <i className="fa fa-times m-r-5" />
                Disable
              </Link>
            )}
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleDeleteClick(record)}
            >
              <i className="fa fa-trash m-r-5" />
              Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  console.log(role, `employees role`);

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            modalClass="col-auto float-end ms-auto"
          />
          {/* /Page Header */}
          <EmployeeListFilter onFilterChange={handleFilterChange} />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-hover"
                  columns={columns}
                  dataSource={filteredEmployees}
                  rowKey={(record) => record._id}
                  pagination={{
                    pageSize: 10, // Number of items per page
                    showSizeChanger: true, // Allows users to change the number of items per page
                    pageSizeOptions: ["10", "20", "50", "100"], // Options for page sizes
                    defaultCurrent: 1, // The default current page
                  }}
                  rowClassName={(record) =>
                    record.status === "disabled"
                      ? `text-light bg-secondary`
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <AllEmployeeAddPopup />
        <DeleteModal
          show={showDeleteModal}
          onDeleteLeave={handleDeleteConfirm}
          closeModal={() => setShowDeleteModal(false)}
          entityName="employee"
        />
      </div>
    </div>
  );
};

export default EmployeeList;
