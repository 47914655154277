export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    role: ["admin", "hr", "manager"],
    menu: [
      {
        menuValue: "All Employees",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employees",
        icon: "la la-user",
      },
      {
        menuValue: "Leaves",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/adminleaves",
        icon: "la la-calendar-check-o",
      },
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/adminattendance",
        icon: "la la-clock-o",
      },
      {
        menuValue: "Departments",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/departments",
        icon: "la la-building-o",
      },
      {
        menuValue: "Designations",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/designations",
        icon: "la la-briefcase",
      },
      {
        menuValue: "Sales",
        hasSubRoute: true,
        showSubRoute: false,

        icon: "la la-money",
        subMenus: [
          {
            menuValue: "SalesFormFeilds",
            route: "/SalesFormFeilds",
          },
          {
            menuValue: "Sales",
            route: "/sales",
          },
        ],
      },

      // {
      //   menuValue: "Admin",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "la la-dashcube",
      //   subMenus: [
      //      {
      //       menuValue: "Admin Dashboard",
      //       route: "/admin-dashboard",
      //     },
      //     {
      //       menuValue: "Employee Dashboard",
      //       route: "/employee-dashboard",
      //     },
      //     {
      //       menuValue: "Manager Dashboard",
      //       route: "/deals-dashboard",
      //     },
      //     {
      //       menuValue: "Leave Settings",
      //       route: "/leave-settings",
      //     },
      //   ],
      // },
    ],
  },
  {
    tittle: "Employee",
    showAsTab: false,
    separateRoute: false,
    role: ["employee", "hr", "manager"],
    menu: [
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/attendance-employee",
        icon: "la la-clock-o",
      },
      {
        menuValue: "Leaves",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/leaves-employee",
        icon: "la la-calendar-check-o",
      },
      // {
      //   menuValue: "Employees",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "la la-user",
      //   subMenus: [
      //     {
      //       menuValue: "Holidays",
      //       route: "/holidays",
      //     },
      //   ],
      // },
    ],
  },

  // {
  //   tittle: "HR",
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: "Payroll",
  //       hasSubRoute: true,
  //       showSubRoute: false,
  //       icon: "la la-money",
  //       subMenus: [
  //         {
  //           menuValue: "Employee Salary",
  //           route: "/salary",
  //         },
  //         {
  //           menuValue: "Payslip",
  //           route: "/salary-view",
  //         },
  //         {
  //           menuValue: "Payroll Items",
  //           route: "/payroll-items",
  //         },
  //       ],
  //     },
  //     {
  //       menuValue: "Policies",
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: "/policies",
  //       icon: "la la-file-pdf-o",
  //     },
  //   ],
  // },

  // {
  //   tittle: "ADMINISTRATION",
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: "Assets",
  //       hasSubRoute: false,
  //       showSubRoute: false,
  //       route: "/assets",
  //       icon: "la la-object-ungroup",
  //     },

  //     // {
  //     //   menuValue: "Jobs",
  //     //   hasSubRoute: true,
  //     //   showSubRoute: false,
  //     //   icon: "la la-briefcase",
  //     //   subMenus: [
  //     //     {
  //     //       menuValue: "User Dasboard",
  //     //       route: "/user-dashboard",
  //     //     },
  //     //     {
  //     //       menuValue: "Jobs Dasboard",
  //     //       route: "/jobs-dashboard",
  //     //     },
  //     //     {
  //     //       menuValue: "Manage Jobs",
  //     //       route: "/jobs",
  //     //     },
  //     //     {
  //     //       menuValue: "Manage Resumes",
  //     //       route: "/manage-resumes",
  //     //     },
  //     //     {
  //     //       menuValue: "Shortlist Candidates",
  //     //       route: "/shortlist-candidates",
  //     //     },
  //     //     {
  //     //       menuValue: "Interview Questions",
  //     //       route: "/interview-questions",
  //     //     },
  //     //     {
  //     //       menuValue: "Offer Approvals",
  //     //       route: "/offer_approvals",
  //     //     },
  //     //     {
  //     //       menuValue: "Experience Level",
  //     //       route: "/experiance-level",
  //     //     },
  //     //     {
  //     //       menuValue: "Candidates List",
  //     //       route: "/candidates",
  //     //     },
  //     //     {
  //     //       menuValue: "Schedule timing",
  //     //       route: "/schedule-timing",
  //     //     },
  //     //     {
  //     //       menuValue: "Aptitude Results",
  //     //       route: "/apptitude-result",
  //     //     },
  //     //   ],
  //     // },

  //     // {
  //     //   menuValue: "Settings",
  //     //   hasSubRoute: false,
  //     //   showSubRoute: false,
  //     //   route: "/company-settings",
  //     //   icon: "la la-cog",
  //     // },
  //   ],
  // },
  // {
  //   tittle: "PAGES",
  //   showAsTab: false,
  //   separateRoute: false,
  //   menu: [
  //     {
  //       menuValue: "Profile",
  //       hasSubRoute: true,
  //       showSubRoute: false,
  //       icon: "la la-user-tag",
  //       subMenus: [
  //         {
  //           menuValue: "Employee Profile",
  //           route: "/profile",
  //         },
  //       ],
  //     },
  //     {
  //       menuValue: "Authentication",
  //       hasSubRoute: true,
  //       showSubRoute: false,
  //       icon: "la la-key",
  //       subMenus: [
  //         {
  //           menuValue: "Login",
  //           route: "/",
  //         },
  //         {
  //           menuValue: "Register",
  //           route: "/register",
  //         },
  //         {
  //           menuValue: "Forgot Password",
  //           route: "/forgot-password",
  //         },
  //         {
  //           menuValue: "OTP",
  //           route: "/otp",
  //         },
  //         {
  //           menuValue: "Lock Screen",
  //           route: "/lock-screen",
  //         },
  //       ],
  //     },
  //     {
  //       menuValue: "Error Pages",
  //       hasSubRoute: true,
  //       showSubRoute: false,
  //       icon: "la la-exclamation-triangle",
  //       subMenus: [
  //         {
  //           menuValue: "404 Error",
  //           route: "/error-404",
  //         },
  //         {
  //           menuValue: "500 Error",
  //           route: "/error-500",
  //         },
  //       ],
  //     },

  //     {
  //       menuValue: "Pages",
  //       hasSubRoute: true,
  //       showSubRoute: false,
  //       icon: "la la-columns",
  //       subMenus: [
  //         {
  //           menuValue: "Search",
  //           route: "/search",
  //         },
  //         {
  //           menuValue: "FAQ",
  //           route: "/faq",
  //         },
  //         {
  //           menuValue: "Terms",
  //           route: "/terms",
  //         },
  //         {
  //           menuValue: "Privacy Policy",
  //           route: "/privacy-policy",
  //         },
  //         {
  //           menuValue: "Blank Page",
  //           route: "/blank-page",
  //         },
  //         {
  //           menuValue: "Coming Soon",
  //           route: "/coming-soon",
  //         },
  //         {
  //           menuValue: "Under Maintenance",
  //           route: "/under-maintenance",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
