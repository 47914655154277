import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import useFetch from "../../../../../hooks/api/fetchHook";
import { useSelector } from "react-redux";
import UsePostData from "../../../../../hooks/api/PostHook";
import { toast } from "react-toastify";
import Loading from "../../../../../hooks/utils/Loading/Loading";
import { deleteSaleFieldOption } from "../../../../../store/slices/EmployeeSlice";
import { useDispatch } from "react-redux";

const CustomOption = (props) => {
  const { data, removeOption } = props;
  return (
    <components.Option className="d-flex justify-content-between  " {...props}>
     <div>{data.label}</div>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          removeOption(data.value);
        }}
        style={{
          marginLeft: 10,
          cursor: "pointer",
          background: "none",
          border: "none",
          color: "red",
          fontSize: "10px",
        }}
      >
        Remove
      </button>
    </components.Option>
  );
};

const SalesFormFeilds = () => {
  const [inputs, setInputs] = useState({
    PurposeOfCall: { value: "", disabled: true, mode: "add" },
    CurrentProvider: { value: "", disabled: true, mode: "add" },
    AccountType: { value: "", disabled: true, mode: "add" },
    CardType: { value: "", disabled: true, mode: "add" },
    QualityControl: { value: "", disabled: true, mode: "add" },
    Status: { value: "", disabled: true, mode: "add" },
    PaymentProcessor: { value: "", disabled: true, mode: "add" },
    PaidOver: { value: "", disabled: true, mode: "add" },
    CallBack: { value: "", disabled: true, mode: "add" },
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    PurposeOfCall: [],
    CurrentProvider: [],
    AccountType: [],
    CardType: [],
    QualityControl: [],
    Status: [],
    PaymentProcessor: [],
    PaidOver: [],
    CallBack: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedOptionToDelete, setSelectedOptionToDelete] = useState(null);
  const [typeToDelete, setTypeToDelete] = useState(null);

  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, loading } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/fields",
    options
  );

  const { postData, isLoading } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/fields",
    options
  );

  useEffect(() => {
    if (apiData?.data) {
      const newDropdownOptions = { ...dropdownOptions };

      apiData.data.forEach((item) => {
        const type = item.type;
        if (newDropdownOptions[type]) {
          newDropdownOptions[type].push({
            value: item.name,
            label: item.name,
            id: item._id,
          });
        }
      });

      setDropdownOptions(newDropdownOptions);
    }
  }, [apiData]);

  const handleButtonClick = async (type) => {
    const { value, mode } = inputs[type];

    if (mode === "add") {
      setInputs({
        ...inputs,
        [type]: { ...inputs[type], disabled: false, mode: "save" },
      });
    } else if (
      mode === "save" &&
      value &&
      !dropdownOptions[type].some((option) => option.value === value)
    ) {
      setDropdownOptions({
        ...dropdownOptions,
        [type]: [...dropdownOptions[type], { value, label: value }],
      });

      const postDataPayload = {
        type,
        name: value,
      };

      try {
        await postData(postDataPayload);
        toast.success("Data saved successfully!");
      } catch (error) {
        console.error("Error saving data:", error);
        toast.error("Error saving data!");
      }

      // Reset input state
      setInputs({
        ...inputs,
        [type]: { ...inputs[type], value: "", disabled: true, mode: "add" },
      });
    }
  };


     const handleRemoveOption = (type, optionValue) => {
    setTypeToDelete(type);
    setSelectedOptionToDelete(optionValue);
    setShowModal(true);
      };
   

     const confirmDeleteOption = async () => {
     const selectedOption = dropdownOptions[typeToDelete].find(
      (option) => option.value === selectedOptionToDelete
     );

    if (selectedOption) {
      // Log the id of the selected option being removed
      console.log("Removing option with id:", selectedOption.id);
      await dispatch(deleteSaleFieldOption(selectedOption.id));
      toast.success("Option Removed successfully!");

      setDropdownOptions({
        ...dropdownOptions,
        [typeToDelete]: dropdownOptions[typeToDelete].filter(
          (option) => option.value !== selectedOptionToDelete
        ),
      });
    }

    setShowModal(false);
    setSelectedOptionToDelete(null);
    setTypeToDelete(null);
  };

  const cancelDeleteOption = () => {
    setShowModal(false);
    setSelectedOptionToDelete(null);
    setTypeToDelete(null);
  };

  const handleInputChange = (type, event) => {
    const { value } = event.target;
    setInputs({
      ...inputs,
      [type]: { ...inputs[type], value },
    });
  };

  if (loading) {
    return <Loading />;
  }
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="row mt-5">
      <div className="col-sm-12">
        <div className="row">
          {[
            "PurposeOfCall",
            "CurrentProvider",
            "AccountType",
            "CardType",
            "QualityControl",
            "Status",
            "PaymentProcessor",
            "PaidOver",
            "CallBack",
          ].map((type) => (
            <div key={type} className="col-sm-6 col-md-3 my-3">
              <label htmlFor={`${type}Input`} className="form-label">
                {type.replace(/([A-Z])/g, " $1").toUpperCase()}
              </label>
              <div
                className="input-group mb-3"
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <input
                  id={`${type}Input`}
                  type="text"
                  className="form-control"
                  placeholder={`Enter ${type.replace(/([A-Z])/g, " $1")}`}
                  value={inputs[type].value}
                  onChange={(e) => handleInputChange(type, e)}
                  disabled={inputs[type].disabled}
                />
                <button
                  className={`btn ${
                    inputs[type].mode === "add" ? "btn-primary" : "btn-success"
                  }`}
                  onClick={() => handleButtonClick(type)}
                  disabled={isLoading}
                >
                  {inputs[type].mode === "add" ? "+" : "✔"}
                </button>
              </div>
              <Select
                id={`${type}Select`}
                options={dropdownOptions[type]}
                components={{
                  Option: (props) => (
                    <CustomOption
                      {...props}
                      removeOption={handleRemoveOption.bind(null, type)}
                    />
                  ),
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    position: "absolute",
                    top: "100%",
                    zIndex: 3,
                    width: "100%",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }),
                }}
                placeholder="Options..."
                onChange={() => {}}
              />
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1040
            }}
          ></div>

          <div
            className="modal custom-modal"
            style={{
              display: "block",
              zIndex: 1050,
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Confirmation</h3>
                    <p>Are you sure you want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action d-flex justify-content-center">
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn btn-primary continue-btn"
                          onClick={confirmDeleteOption}
                          data-bs-dismiss="modal"
                        >
                          Delete
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary cancel-btn"
                          onClick={cancelDeleteOption}
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

          </div>
        );
      };

export default SalesFormFeilds;
