import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import useFetch from "../../../src/hooks/api/fetchHook";

const DepartmentModal = ({ onSubmit, onEdit, editDepartment, mode }) => {
  const { userToken } = useSelector((state) => state?.auth);
  const [departmentName, setDepartmentName] = useState("");
  const [description, setDescription] = useState("");
  const [manager, setManager] = useState(null);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const {
    apiData,
    isLoading: fetchLoading,
    isError: fetchError,
  } = useFetch("https://zolara-crm-backend.vercel.app/api/users/", options);

  useEffect(() => {
    if (fetchLoading) {
      setIsLoading(true);
    } else if (fetchError) {
      setIsError(true);
      setIsLoading(false);
    } else if (apiData) {
      setManagers(apiData.data.users || []);
      setIsLoading(false);
    }
  }, [fetchLoading, fetchError, apiData]);

  useEffect(() => {
    if (mode === "edit" && editDepartment) {
      setDepartmentName(editDepartment.departmentName);
      setDescription(editDepartment.description);
      setManager({
        label: editDepartment.managerName,
        value: editDepartment.manager,
      });
    } else {
      setDepartmentName("");
      setDescription("");
      setManager(null);
    }
  }, [mode, editDepartment]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const departmentData = {
      departmentName,
      description,
      manager: manager ? manager.value : "",
    };

    console.log("Form Submitted with Data:", departmentData);

    if (mode === "edit") {
      onEdit(departmentData);
    } else {
      onSubmit(departmentData);
    }

    // Close the modal using a direct DOM method
    const modalId = mode === "edit" ? "edit_department" : "add_department";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement.querySelector(".btn-close");
    if (closeButton) closeButton.click(); // Simulate the close button click

    // Clear form fields after submission
    setDepartmentName("");
    setDescription("");
    setManager(null);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#000" : "#000", // Ensure text is visible
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 140,
      overflowY: "auto",
    }),
  };

  return (
    <>
      <div
        id={mode === "edit" ? "edit_department" : "add_department"}
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {mode === "edit" ? "Edit Department" : "Add Department"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">Manager</label>
                  <Select
                    placeholder="Select Manager"
                    value={manager}
                    onChange={(option) => setManager(option)}
                    options={managers.map((mgr) => ({
                      label: mgr.name,
                      value: mgr._id,
                    }))}
                    styles={customStyles}
                    isLoading={isLoading}
                    noOptionsMessage={() =>
                      isError ? "Error loading managers" : "No managers found"
                    }
                  />
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentModal;
