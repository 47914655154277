import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { fetchDepartments } from "../../../store/slices/EmployeeSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const ProfileTab = ({ userDetails }) => {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state?.employee?.departments);
  const designations = useSelector((state) => state?.employee?.designations);
  const [editModalName, setEditModalName] = useState(null);

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  const jobExperiences = [
    {
      companyName: "Smith, Olson and Baker",
      location: "Taylorport",
      jobPosition: "Occupational Hygienist",
      periodFrom: "2021-06-17",
      periodTo: null, // Present
    },
    {
      companyName: "Sparks PLC",
      location: "North Jeremymouth",
      jobPosition: "Risk Manager",
      periodFrom: "2020-06-12",
      periodTo: null, // Present
    },
    {
      companyName: "Perry LLC",
      location: "West Jeffrey",
      jobPosition: "Camera Operator",
      periodFrom: "2021-09-10",
      periodTo: null, // Present
    },
    {
      companyName: "Jones, Shelton and Lawson",
      location: "Jonesmouth",
      jobPosition: "Counsellor",
      periodFrom: "2022-06-18",
      periodTo: "2023-07-06",
    },
    {
      companyName: "Carrillo LLC",
      location: "South Jasonberg",
      jobPosition: "Forensic Psychologist",
      periodFrom: "2022-04-26",
      periodTo: "2023-04-02",
    },
    {
      companyName: "Smith, Olson and Baker",
      location: "Taylorport",
      jobPosition: "Occupational Hygienist",
      periodFrom: "2021-06-17",
      periodTo: null, // Present
    },
    {
      companyName: "Sparks PLC",
      location: "North Jeremymouth",
      jobPosition: "Risk Manager",
      periodFrom: "2020-06-12",
      periodTo: null, // Present
    },
    {
      companyName: "Perry LLC",
      location: "West Jeffrey",
      jobPosition: "Camera Operator",
      periodFrom: "2021-09-10",
      periodTo: null, // Present
    },
    {
      companyName: "Jones, Shelton and Lawson",
      location: "Jonesmouth",
      jobPosition: "Counsellor",
      periodFrom: "2022-06-18",
      periodTo: "2023-07-06",
    },
    {
      companyName: "Carrillo LLC",
      location: "South Jasonberg",
      jobPosition: "Forensic Psychologist",
      periodFrom: "2022-04-26",
      periodTo: "2023-04-02",
    },
  ];

  function calculateMonthsAndYears(fromDate, toDate) {
    let startDate = new Date(fromDate);
    let endDate = toDate ? new Date(toDate) : new Date();

    let years = endDate.getFullYear() - startDate.getFullYear();
    let months = endDate.getMonth() - startDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }
    const yearConvert = years ? `${years} years` : "";
    const monthConvert = months ? ` ${months} months` : "";

    return yearConvert + monthConvert;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
    });
  };

  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Education Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#education_info"
                      onClick={() => setEditModalName("Education")}
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {userDetails?.education?.map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.institution}
                              </Link>
                              <div>{item.degree}</div>
                              <div>( {item.subject} )</div>
                              <div className="d-flex">
                                <div>{formatDate(item.startingDate)}</div>
                                &nbsp; - &nbsp;
                                <div>{formatDate(item.completeDate)}</div>
                              </div>
                              <div> Marks | {item.grade}</div>
                              <span className="time">{item.time}</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {console.log('userDetails?.experience')}
                      {userDetails?.experience?.length
                        ? userDetails?.experience.map((item) => (
                            <li key={item.id}>
                              <div className="experience-user">
                                <div className="before-circle" />
                              </div>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  {item.jobPosition} at {item.companyName}
                                  <span className="time">
                                    {formatDate(item.periodFrom)} -{" "}
                                    {item.periodTo
                                      ? formatDate(item.periodTo)
                                      : "Present"}{" "}
                                    (
                                    {calculateMonthsAndYears(
                                      item.periodFrom,
                                      item.periodTo
                                    )}
                                    )
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))
                        : "No Record Found"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PersonalInformationModelPopup
        designationList={designations}
        departmentList={departments}
        editModalName={editModalName}
        userId={userDetails?._id}
      />
    </>
  );
};

export default ProfileTab;
