import { useCallback } from 'react';

const DateFormat = () => {
  const formatDateTime = useCallback((isoString, format = 'datetime') => {
    if (!isoString || isNaN(new Date(isoString).getTime())) {
      return 'N/A'; // Return an empty string if the input is invalid
    }

    const date = new Date(isoString);

    const dayName = date.toLocaleDateString('en-US', { weekday: 'short' }); // e.g., 'Wed'
    const day = date.getDate(); // e.g., 11
    const month = date.toLocaleDateString('en-US', { month: 'short' }); // e.g., 'Mar'
    const year = date.getFullYear(); // e.g., 2023

    const nth = (d) => {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const formattedDay = `${day}${nth(day)}`; // e.g., '11th'

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: format === 'datetime' ? 'numeric' : undefined,
      hour12: true,
    }); // e.g., '10:00 AM'

    if (format === 'time') {
      return time; // Return only time
    }

    if (format === 'date') {
      return `${formattedDay} ${month} ${year}`; // Return only date
    }

    return `${dayName}, ${formattedDay} ${month} ${year} ${time}`; // Default to datetime format
  }, []);

  return { formatDateTime };
};

export default DateFormat;
