import React, { useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import InvoiceTable from "./invoiceTable";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";

const Invoices = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTwo, setSelectedDateTwo] = useState(null);
  const [status, setStatus] = useState(null); // Store selected status
  const [filteredValues, setFilteredValues] = useState([]);

  const handleSearch = () => {
    // Pass selected dates and status for filtering
    const filters = {
      startDate: selectedDate,
      endDate: selectedDateTwo,
      status: status?.value || null, // Check if status is selected
    };
    setFilteredValues(filters); // Set filters to pass to the table
  };

  const options = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
    { value: "Partially Paid", label: "Partially Paid" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Invoices"
            title="Dashboard"
            subtitle="Invoices"
            modal="#"
            name="Create Invoice"
            link="/create-invoice"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3 form-focus">
                <div className="cal-icon focused ">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3 form-focus">
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker "
                    selected={selectedDateTwo}
                    onChange={(date) => setSelectedDateTwo(date)}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Status"
                  onChange={setStatus}
                  options={options}
                  styles={customStyles}
                />
                <label className="focus-label">Status</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <button className="btn btn-success w-100" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>

          <InvoiceTable filters={filteredValues} />
        </div>
      </div>
      <DeleteModal Name="Delete Invoice" />
    </>
  );
};

export default Invoices;
