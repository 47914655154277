// // src/slices/leaveSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://zolara-crm-backend.vercel.app";

// Fetch all leaves (Admin)
export const fetchAllLeaves = createAsyncThunk(
  "leave/fetchAllLeaves",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(`${base_url}/api/leaves`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response?.data?.data?.leaves;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchLeavesByUserId = createAsyncThunk(
  "leave/fetchLeavesByUserId",
  async (userId, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(
        `${base_url}/api/leaves/user/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.leaves;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// // Create a leave request (Employee)
export const createLeave = createAsyncThunk(
  "leave/createLeave",
  async (leaveData, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.post(`${base_url}/api/leaves`, leaveData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.leave;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// // Update a leave request (Employee)
export const updateLeave = createAsyncThunk(
  "leave/updateLeave",
  async ({ id, leaveData }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.patch(
        `${base_url}/api/leaves/${id}`,
        leaveData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.leave;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Approve a leave request (Admin)
export const approveLeave = createAsyncThunk(
  "leave/approveLeave",
  async ({ id, approvedBy }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.patch(
        `${base_url}/api/leaves/${id}/approve`,
        { approvedBy },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.leave;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Reject a leave request (Admin)
export const rejectLeave = createAsyncThunk(
  "leave/rejectLeave",
  async ({ id, rejectedBy }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.patch(
        `${base_url}/api/leaves/${id}/reject`,
        { rejectedBy },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.leave;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Delete a leave request (Admin/Employee)
export const deleteLeave = createAsyncThunk(
  "leave/deleteLeave",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      await axios.delete(`${base_url}/api/leaves/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// const leaveSlice = createSlice({
//   name: "leave",
//   initialState: {
//     leaves: [],
//     status: "idle",
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchAllLeaves.pending, (state) => {
//         state.status = "loading";
//       })
//       .addCase(fetchAllLeaves.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.leaves = action.payload;
//       })
//       .addCase(fetchAllLeaves.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload;
//       })
//       .addCase(approveLeave.fulfilled, (state, action) => {
//         const index = state.leaves.findIndex(
//           (leave) => leave._id === action.payload._id
//         );
//         if (index !== -1) {
//           state.leaves[index] = action.payload;
//         }
//       })
//       .addCase(rejectLeave.fulfilled, (state, action) => {
//         const index = state.leaves.findIndex(
//           (leave) => leave._id === action.payload._id
//         );
//         if (index !== -1) {
//           state.leaves[index] = action.payload;
//         }
//       })
//       .addCase(deleteLeave.fulfilled, (state, action) => {
//         state.leaves = state.leaves.filter(
//           (leave) => leave._id !== action.payload
//         );
//       });
//   },
// });
const leaveSlice = createSlice({
  name: "leave",
  initialState: {
    leaves: [],
    status: {
      fetchAllLeaves: "idle",
      fetchByUserId: "idle",
      createLeave: "idle",
      updateLeave: "idle",
      deleteLeave: "idle",
    },
    error: {
      fetchAllLeaves: null,
      fetchByUserId: null,
      createLeave: null,
      updateLeave: null,
      deleteLeave: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all leaves
      .addCase(fetchAllLeaves.pending, (state) => {
        state.status.fetchAllLeaves = "loading";
      })
      .addCase(fetchAllLeaves.fulfilled, (state, action) => {
        state.status.fetchAllLeaves = "succeeded";
        state.leaves = action.payload;
      })
      .addCase(fetchAllLeaves.rejected, (state, action) => {
        state.status.fetchAllLeaves = "failed";
        state.error.fetchAllLeaves = action.payload;
      })

      // Fetch leaves by userId
      .addCase(fetchLeavesByUserId.pending, (state) => {
        state.status.fetchByUserId = "loading";
      })
      .addCase(fetchLeavesByUserId.fulfilled, (state, action) => {
        state.status.fetchByUserId = "succeeded";
        state.leaves = action.payload;
      })
      .addCase(fetchLeavesByUserId.rejected, (state, action) => {
        state.status.fetchByUserId = "failed";
        state.error.fetchByUserId = action.payload;
      })

      // Handle other async actions similarly...

      // Delete leave
      .addCase(deleteLeave.fulfilled, (state, action) => {
        state.leaves = state.leaves.filter(
          (leave) => leave._id !== action.payload
        );
      });
  },
});

export default leaveSlice.reducer;
