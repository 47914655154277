import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { Avatar_02 } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  fetchDesignations,
} from "../../store/slices/EmployeeSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const PersonalInformationModelPopup = ({
  userId,
  editModalName,
  departmentList,
  designationList,
}) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null); // State to store the file preview URL
  const [selectedDate1, setSelectedDate1] = useState(null);
  const designations = useSelector((state) => state?.employee?.designations);


  const [educationStartingDates, setEducationStartingDates] = useState([]);

  const [educationCompletionDate, SetEducationCompletionDate] = useState([]);
  // State for different form sections
  const token = useSelector((state) => state?.auth?.userInfo?.userToken);
  const profileInfo = useSelector(
    (state) => state?.user?.userDetails?.data?.user
  );
  const [formCount, setFormCount] = useState(
    profileInfo?.education.length || 0
  );
  const [formData, setFormData] = useState({});
  // const [file, setFile] = useState();

  useEffect(() => {
    if (editModalName === "Education") {
      setFormData((prevData) => ({
        ...prevData,
        education: profileInfo?.education || [],
      }));
    }
  }, [editModalName, profileInfo]);

  useEffect(() => {
    if (profileInfo?.department) {
      dispatch(fetchDesignations(profileInfo?.department?._id));
    }
  }, [dispatch, profileInfo?.department]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const transformedData = departmentList.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  console.log("transformedData", departmentList);
  // const defaultOption = transformedData.find(option => option.value === profileInfo?.department?._id);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleChange("image", selectedFile); // Update formData with file
      // setFile(selectedFile); // Update file state

      // Generate a preview URL for the selected file
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }
  };

  const handleAddForm = () => {
    setFormCount((prevCount) => prevCount + 1);
  };
  const handleEducationChange = (index, field, value) => {
    if (profileInfo?.education.length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        education: {
          ...prevData.education,
          [field]: value,
        },
      }));
    } else {
      setFormData((prevData) => {
        const updatedEducation = [...prevData.education];

        updatedEducation[index] = {
          ...updatedEducation[index],
          [field]: value,
        };

        return {
          ...prevData,
          education: updatedEducation,
        };
      });
    }
  };
  const handleDeleteEducation = async (dataId, section) => {
    if (dataId) {
      const filterFormData = profileInfo?.education?.filter(
        (item) => item._id !== dataId
      );
      const newEducationData = {
        education: filterFormData,
      };
      const data = new FormData();
      Object.keys(newEducationData).forEach((key) => {
        if (Array.isArray(newEducationData[key])) {
          data.append(key, JSON.stringify(newEducationData[key]));
        } else {
          data.append(key, newEducationData[key]);
        }
      });
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.patch(
          `https://zolara-crm-backend.vercel.app/api/users/${userId}`,
          data,
          config
        );

        if (response.status === 200) {
          toast.success(`Education updated successfully!`);
        }
      } catch (error) {
        console.error(
          `Error updating Education:`,
          error.response ? error.response.data : error.message
        );
        toast.danger(`Failed to update education. Please check you provided data`);
      }
    }
    if (formCount > profileInfo?.education?.length) {
      setFormCount(formCount - 1);
    }
  };

  const handleSubmit = async (e, section) => {
    e.preventDefault();

    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "education") {
        data.append(key, JSON.stringify(formData[key]));
      } else {
        data.append(key, formData[key]);
      }
    });

    console.log("data", formData);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.patch(
        `https://zolara-crm-backend.vercel.app/api/users/${userId}`,
        data,
        config
      );
      if (response.status === 200) {
      
        toast.success(`${section} updated successfully!`);
    
      }
    } catch (error) {
      console.error(
        `Error updating ${section}:`,
        error.response ? error.response.data : error.message
      );
      toast.danger(`Failed to update ${section}. Please try again.`);
    }
  };

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
    handleChange("dateOfBirth", date);
  };

  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      {/* Profile Information Modal */}
      <div id="profile_info" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Profile Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleSubmit(e, "personal-info")}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={
                          preview ||
                          (profileInfo?.image ? profileInfo.image : Avatar_02)
                        } //
                        alt="user"
                      />
                      <div className="fileupload btn">
                        <span className="btn-text">edit</span>
                        <input
                          className="upload"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your First Name"
                            defaultValue={
                              profileInfo?.firstName
                                ? profileInfo.firstName
                                : ""
                            }
                            onChange={(e) =>
                              handleChange("firstName", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Last Name"
                            defaultValue={
                              profileInfo?.lastName ? profileInfo.lastName : ""
                            }
                            onChange={(e) =>
                              handleChange("lastName", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Phone Number"
                            defaultValue={
                              profileInfo?.phone ? profileInfo.phone : ""
                            }
                            onChange={(e) =>
                              handleChange("phone", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Your Email Address"
                            defaultValue={
                              profileInfo?.email ? profileInfo.email : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Birth Date</label>
                          <DatePicker
                            selected={
                              formData?.dateOfBirth
                                ? formData.dateOfBirth
                                : profileInfo?.dateOfBirth
                                ? profileInfo.dateOfBirth
                                : selectedDate1
                            }
                            placeholder="DD-MM-YYYY"
                            value={formData.dateOfBirth}
                            onChange={handleDateChange1}
                            className="form-control floating datetimepicker"
                            dateFormat="dd-MM-yyyy"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Gender</label>
                          <Select
                            options={gender}
                            placeholder="Gender"
                            styles={customStyles}
                            defaultInputValue={
                              profileInfo?.gender ? profileInfo.gender : ""
                            }
                            value={gender.find(
                              (g) => g.value === formData.gender
                            )}
                            onChange={(option) =>
                              handleChange("gender", option.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Department <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={transformedData}
                            defaultInputValue={
                              profileInfo?.department
                                ? profileInfo?.department?.name
                                : ""
                            }
                            placeholder="Select Department"
                            styles={customStyles}
                            onChange={(option) => {
                              handleChange("department", option.value);
                              dispatch(fetchDesignations(option.value));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Designation <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={designations}
                            defaultInputValue={
                              profileInfo?.designation
                                ? profileInfo?.designation?.title
                                : ""
                            }
                            placeholder="Select Designation"
                            styles={customStyles}
                            onChange={(value) => {
                              console.log('value', value);
                              handleChange("designation", value?._id);
                            }}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Address</label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Your Address"
                            defaultValue={
                              profileInfo?.address ? profileInfo.address : ""
                            }
                            onChange={(e) =>
                              handleChange("address", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Zip Code</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Zip Code"
                            defaultValue={
                              profileInfo?.zipCode ? profileInfo.zipCode : ""
                            }
                            onChange={(e) =>
                              handleChange("zip", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Education Modal */}
      <div
        id="education_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Education Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleSubmit(e, "education-info")}>
                {profileInfo &&
                  [...Array(formCount)].map((_, index) => {
                    return (
                      <>
                        <div className="form-scroll">
                          <div className="card">
                            <div className="card-body">
                              <h3 className="card-title">
                                {/* Education Informations */}
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDeleteEducation(
                                      profileInfo?.education[index]?._id,
                                      "Education Informations"
                                    )
                                  }
                                  className="delete-icon bg-white border border-0"
                                >
                                  <i className="fa-regular fa-trash-can" />
                                </button>
                              </h3>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Institution
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      defaultValue={
                                        profileInfo?.education[index]
                                          ?.institution || " "
                                      }
                                      onChange={(e) =>
                                        handleEducationChange(
                                          index,
                                          "institution",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Subject
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      defaultValue={
                                        profileInfo?.education[index]
                                          ?.subject || " "
                                      }
                                      onChange={(e) =>
                                        handleEducationChange(
                                          index,
                                          "subject",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Starting Date
                                    </label>
                                    <DatePicker
                                      selected={
                                        educationStartingDates[index] ||
                                        profileInfo?.education[index]
                                          ?.startingDate
                                      }
                                      onChange={(date) => {
                                        if (date) {
                                          const day = String(
                                            date.getDate()
                                          ).padStart(2, "0");
                                          const month = String(
                                            date.getMonth() + 1
                                          ).padStart(2, "0"); // Months are zero-based
                                          const year = date.getFullYear();
                                          const formattedDate = `${day}-${month}-${year}`;
                                          handleEducationChange(
                                            index,
                                            "startingDate",
                                            formattedDate
                                          );
                                          setEducationStartingDates(
                                            (prevDates) => {
                                              const newDates = [...prevDates];
                                              newDates[index] = date;
                                              return newDates;
                                            }
                                          );
                                        } else {
                                          setEducationStartingDates(
                                            (prevDates) => {
                                              const newDates = [...prevDates];
                                              newDates[index] = "";
                                              return newDates;
                                            }
                                          );
                                          handleEducationChange(
                                            index,
                                            "startingDate",
                                            ""
                                          );
                                        }
                                      }}
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Complete Date
                                    </label>
                                    <DatePicker
                                      selected={
                                        educationCompletionDate[index] ||
                                        profileInfo?.education[index]
                                          ?.completeDate
                                      }
                                      onChange={(date) => {
                                        if (date) {
                                          const day = String(
                                            date.getDate()
                                          ).padStart(2, "0");
                                          const month = String(
                                            date.getMonth() + 1
                                          ).padStart(2, "0"); // Months are zero-based
                                          const year = date.getFullYear();
                                          const formattedDate = `${day}-${month}-${year}`;

                                          handleEducationChange(
                                            index,
                                            "completeDate",
                                            formattedDate
                                          );
                                          SetEducationCompletionDate(
                                            (prevDates) => {
                                              const newDates = [...prevDates];
                                              newDates[index] = date;
                                              return newDates;
                                            }
                                          );
                                        } else {
                                          SetEducationCompletionDate(
                                            (prevDates) => {
                                              const newDates = [...prevDates];
                                              newDates[index] = "";
                                              return newDates;
                                            }
                                          );
                                          handleEducationChange(
                                            index,
                                            "completeDate",
                                            ""
                                          );
                                        }
                                      }}
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Degree
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      defaultValue={
                                        profileInfo?.education[index]?.degree ||
                                        " "
                                      }
                                      onChange={(e) =>
                                        handleEducationChange(
                                          index,
                                          "degree",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Grade
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      defaultValue={
                                        profileInfo?.education[index]?.grade ||
                                        " "
                                      }
                                      onChange={(e) =>
                                        handleEducationChange(
                                          index,
                                          "grade",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                <div className="add-more">
                  <button
                    disabled={formCount === profileInfo?.education.length + 1}
                    className="border border-0 bg-white"
                    onClick={handleAddForm}
                    type="button"
                  >
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </button>
                </div>

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Experience Modal */}

      <div
        id="experience_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Experience Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleSubmit(e, "experience-info")}>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">
                        Experience Informations
                        <Link to="#" className="delete-icon">
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      {profileInfo &&
                        profileInfo?.experience.map((experienceInfo) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      defaultValue={experienceInfo.companyName}
                                      onChange={(e) =>
                                        handleChange(
                                          "experienceCompanyName",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Location
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      defaultValue={experienceInfo.location}
                                      onChange={(e) =>
                                        handleChange(
                                          "experienceLocation",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Job Position
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control floating"
                                      // value={formData.experienceJobPosition}
                                      defaultValue={experienceInfo.jobPosition}
                                      onChange={(e) =>
                                        handleChange(
                                          "experienceJobPosition",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Period From
                                    </label>
                                    <DatePicker
                                      selected={
                                        formData?.periodFrom
                                          ? formData.periodFrom
                                          : experienceInfo.periodFrom
                                          ? experienceInfo.periodFrom
                                          : selectedDate1
                                      }
                                      onChange={(date) =>
                                        handleChange(
                                          "experiencePeriodFrom",
                                          date
                                        )
                                      }
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Period To
                                    </label>
                                    <DatePicker
                                      selected={
                                        formData?.periodTo
                                          ? formData.periodTo
                                          : experienceInfo.periodTo
                                          ? experienceInfo.periodTo
                                          : selectedDate1
                                      }
                                      onChange={(date) =>
                                        handleChange("experiencePeriodTo", date)
                                      }
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="add-more">
                    <Link to="#">
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInformationModelPopup;
