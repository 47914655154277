import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import DepartmentModal from "../../../components/modelpopup/DepartmentModal";
import useFetch from "../../../hooks/api/fetchHook";
import UsePostData from "../../../hooks/api/PostHook";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import {
  updateDepartment,
  deleteDepartment,
} from "../../../store/slices/EmployeeSlice";

const Department = () => {
  const { userToken } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [mode, setMode] = useState();
  const [loading, setLoading] = useState(false);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, isLoading, isError } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/departments",
    options,
    refreshData
  );

  const departmentData = apiData?.data?.departments || [];
  console.log("departmentdata", departmentData);
  const departmentElements = departmentData.map((department, index) => ({
    key: index,
    departmentId: department._id,
    id: index + 1,
    departmentName: department.name,
    description: department.description,
    manager:
      department?.manager?.firstName + " " + department?.manager?.lastName ||
      "N/A",
  }));

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      width: "10%",
    },
    {
      title: "Department Name",
      dataIndex: "departmentName",
      sorter: (a, b) => a.departmentName.length - b.departmentName.length,
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
      width: "40%",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      sorter: (a, b) => a.manager.length - b.manager.length,
      width: "10%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_department"
              onClick={() => handleEditDepartment(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteDepartment(record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const { postData } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/departments"
  );

  const handleAddDepartment = async (departmentData) => {
    setLoading(true);
    const { departmentName, description, manager } = departmentData;

    const requestBody = {
      name: departmentName,
      description: description,
      manager: manager || null,
      employees: [],
    };

    const result = await postData(requestBody);
    if (result.status === "success") {
      console.log("Department added successfully");
      setRefreshData((prev) => !prev);
    } else {
      console.error("Failed to add department:", result.message);
    }
    setLoading(false);
  };

  const handleEditDepartment = (department) => {
    setSelectedDepartment(department);
    console.log("edit designation data ////////////////////////", department);
    setMode("edit");
  };

  const handleUpdateDepartment = async (departmentData) => {
    setLoading(true); // Show loader
    const { description, manager } = departmentData;

    if (selectedDepartment) {
      await dispatch(
        updateDepartment({
          departmentId: selectedDepartment.departmentId,
          description,
          manager,
          token: userToken,
        })
      );
      setSelectedDepartment(null);
      setMode("edit");
      setRefreshData((prev) => !prev);
    }
    setLoading(false);
  };

  const handleDeleteDepartment = (department) => {
    setSelectedDepartment(department);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = () => {
    if (selectedDepartment) {
      dispatch(deleteDepartment(selectedDepartment.departmentId))
        .then(() => {
          setIsDeleteModalOpen(false);
          setRefreshData((prev) => !prev);
        })
        .catch((error) => {
          console.error("Failed to delete department:", error);
        });
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Department"
          title="Dashboard"
          subtitle="Department"
          modal="#add_department"
          name="Add Department"
          modalClass="col-auto float-end ms-auto"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <SearchBox />
              <Table
                columns={columns}
                dataSource={isLoading || isError ? [] : departmentElements}
                className="table-striped"
                rowKey="id"
                locale={{
                  emptyText: isLoading ? (
                    <div>
                      <Loading /> {/* Shows loading spinner in table */}
                    </div>
                  ) : isError ? (
                    <div className="text-center text-danger">
                      <Error404 />
                    </div>
                  ) : (
                    "No Data"
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => setMode("add")}>
        <DepartmentModal
          onSubmit={handleAddDepartment}
          onEdit={handleUpdateDepartment}
          editDepartment={selectedDepartment}
          mode={mode}
        />
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onDeleteLeave={handleDelete}
          closeModal={closeDeleteModal}
        />
      )}
      {loading && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Department;
