import React, { useState, useEffect } from "react";
import Select from "react-select";
import useFetch from "../../hooks/api/fetchHook";
import { useSelector } from "react-redux";
import UsePostData from "../../hooks/api/PostHook";
import Loading from "../../hooks/utils/Loading/Loading";
import { toast } from "react-toastify";

const AddDesignationModelPopup = ({ onSubmit, onEdit, editDesignation, mode }) => {
  const { userToken } = useSelector((state) => state?.auth);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, loading } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/departments",
    options
  );

  const designationOptions = apiData?.data?.departments
    ? apiData.data.departments.map((department) => ({
      value: department._id,
      label: department.name,
    }))
    : [{ value: 1, label: "Select Department" }];

  const [description, setDescription] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  // Pre-fill or reset fields based on mode
  useEffect(() => {
    if (mode === "edit" && editDesignation) {
      // Pre-fill form fields in edit mode
      setDesignationName(editDesignation.title);
      setDescription(editDesignation.description);
      setSelectedDepartment({
        value: editDesignation.department._id,
        label: editDesignation.department.name,
      });
    } else {
      // Clear form fields in add mode
      setDesignationName("");
      setDescription("");
      setSelectedDepartment(null);
    }
  }, [mode, editDesignation]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const { postData, isLoading } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/designations", options
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!designationName || !selectedDepartment) {
      alert("Please fill in all required fields.");
      return;
    }

    const payload = {
      title: designationName,
      department: selectedDepartment.value,
      description,
    };

    if (mode === "edit") {
      await onEdit(payload); // Call edit function
    } else {
      const result = await postData(payload); // Call add function
      if (result.status === "success") {
        toast.success("Designation added successfully");
      } else {
        toast.error("Failed to add designation");
      }
    }

    // Reset form fields
    setDesignationName("");
    setDescription("");
    setSelectedDepartment(null);
  };

  if (isLoading || loading) {
    return <Loading />;
  }

  return (
    <>
      {/* Modal ID based on mode */}
      <div
        id={mode === "edit" ? "edit_designation" : "add_designation"}
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {mode === "edit" ? "Edit Designation" : "Add Designation"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={designationName}
                    onChange={(e) => setDesignationName(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={designationOptions}
                    placeholder="Select Department"
                    styles={customStyles}
                    value={selectedDepartment}
                    onChange={(option) => setSelectedDepartment(option)}
                  />
                </div>

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDesignationModelPopup;
