import React from "react";

const AttendenceModelPopup = ({ attendanceData }) => {
  if (!attendanceData) return null;

  const formatTime = (timeString) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(timeString).toLocaleTimeString("en-US", options);
  };

  return (
    <div className="modal custom-modal fade" id="attendance_info" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Attendance Info</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="card punch-status">
                  <div className="card-body">
                    <h5 className="card-title">
                      Timesheet{" "}
                      <small className="text-muted">
                        {new Date(attendanceData.date).toLocaleDateString(
                          "en-GB"
                        )}
                      </small>
                    </h5>
                    <div className="punch-det">
                      <h6>Punch In at</h6>
                      <p>{formatTime(attendanceData.punches[0]?.time)}</p>{" "}
                    </div>
                    <div className="punch-info">
                      <div className="punch-hours">
                        <span>{attendanceData.totalHours} hrs</span>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="punch-det w-50">
                        <h6>Break</h6>
                        <p>{attendanceData.breaks} mins</p>
                      </div>
                      <div className="punch-det w-50">
                        <h6>Overtime</h6>
                        <p>{attendanceData.overtime} hrs</p>
                      </div>
                    </div>
                    <div className="punch-det">
                      <h6>Punch Out at</h6>
                      <p>
                        {formatTime(
                          attendanceData.punches[
                            attendanceData.punches.length - 1
                          ]?.time
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card recent-activity">
                  <div className="card-body">
                    <h5 className="card-title">Activity</h5>
                    <ul className="res-activity-list">
                      {attendanceData.punches.map((punch) => (
                        <li key={punch._id} className="d-flex">
                          <div className="admin-icon-area">
                            <i
                              class="fa-regular fa-circle fa-xs"
                              id="circle-icon"
                            ></i>
                            <div className="punch-line"></div>
                          </div>
                          <div className="punch-main">
                            <p className="mb-0">
                              {punch.type === "in" ? "Punch In" : "Punch Out"}{" "}
                              at
                            </p>
                            <p className="res-activity-time">
                              <i className="fa fa-clock-o" />
                              {formatTime(punch.time)}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendenceModelPopup;
