import React from "react";
import DefaultEditor from "react-simple-wysiwyg";

const TextEditor = () => {
  return (
    <div>
      <DefaultEditor />
    </div>
  );
};

export default TextEditor;
