import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import AttendanceEmployeeFilter from "../../../components/AttendanceEmployeeFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DateFormat from "../../../hooks/utils/DateFormat";
import useFetch from "../../../hooks/api/fetchHook";
import UsePostData from "../../../hooks/api/PostHook";
import Loading from "../../../hooks/utils/Loading/Loading";

const AttendanceEmployee = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filteredAttendances, setFilteredAttendances] = useState([]);
  const [initialVal, setInitialValue] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const timerRef = useRef(null);
  // const [punchType, setPunchType] = useState(null);
  const [loadData, setLoadData] = useState(false);

  const { userToken, userId } = useSelector((state) => ({
    userToken: state?.auth?.userToken,
    userId: state?.auth?.userInfo?.id,
  }));

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { postData: postPunchIn } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/attendance/punch-in"
  );
  const { postData: postPunchOut } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/attendance/punch-out"
  );

  const { apiData, loading, error } = useFetch(
    `https://zolara-crm-backend.vercel.app/api/attendance/${userId}`,
    options,
    loadData
  );

  const lastAttendanceIndex = apiData?.data?.attendances?.length - 1;
  const lastPunchIndex =
    apiData?.data?.attendances?.[lastAttendanceIndex]?.punches?.length - 1;

  const lastPunchTime =
    apiData?.data?.attendances?.[lastAttendanceIndex]?.punches?.[lastPunchIndex]
      ?.time;

  const { formatDateTime } = DateFormat();

  const punches =
    apiData?.data?.attendances[lastAttendanceIndex]?.punches || [];

  useEffect(() => {
    if (initialVal && apiData?.data?.attendances) {
      const totalPunches =
        apiData?.data?.attendances[lastAttendanceIndex]?.punches?.length - 1;
      const lastDate = apiData?.data?.attendances[lastAttendanceIndex]?.date;
      const isToday =
        new Date(lastDate).toDateString() === new Date().toDateString();
      console.log("isToday", isToday);
      // if (!isToday) {
      //   setTime(0);
      //   return;
      // }
      const totalTime = Math.floor(
        apiData?.data?.attendances[lastAttendanceIndex]?.totalHours * 60
      );
      setTime(totalTime);

      if (
        apiData?.data?.attendances[lastAttendanceIndex]?.punches[totalPunches]
          ?.type === "in"
      ) {
        const givenTime =
          apiData?.data?.attendances[lastAttendanceIndex]?.punches[totalPunches]
            ?.time;

        const currentTime = new Date();
        const targetTime = new Date(givenTime);
        const differenceInSeconds = Math.floor(
          (currentTime - targetTime) / 1000
        );

        setTime(totalTime + differenceInSeconds);
        timerRef.current = setInterval(() => {
          setTime((prevTime) => prevTime + 1);
        }, 1000);
        setIsRunning(true);
        // setPunchType("in");
      }
    }
  }, [initialVal]);

  useEffect(() => {
    if (apiData?.data?.attendances) {
      setFilteredAttendances(apiData.data.attendances);
      setInitialValue(true);
    }
  }, [apiData]);

  useEffect(() => {
    if (!initialVal && !loading && error !== "") {
      setInitialValue(true);
    }
  }, [initialVal, loading, error]);

  const filterAttendances = () => {
    if (!apiData?.data?.attendances) return [];

    const filtered = apiData.data.attendances.filter((attendance) => {
      const attendanceDate = new Date(attendance.date);
      const matchesDate = selectedDate
        ? attendanceDate.getDate() === parseInt(selectedDate)
        : true;
      const matchesMonth = selectedMonth
        ? attendanceDate.getMonth() + 1 === selectedMonth.value
        : true;
      const matchesYear = selectedYear
        ? attendanceDate.getFullYear() === selectedYear.value
        : true;
      return matchesDate && matchesMonth && matchesYear;
    });

    setFilteredAttendances(filtered);
  };

  const userElements =
    filteredAttendances?.map((user, index) => {
      const punchesOut = user.punches.filter((punch) => punch.type === "out");
      const lastPunchOut = punchesOut[punchesOut.length - 1];

      return {
        key: index,
        id: index + 1,
        Date: formatDateTime(user.date, "date"),
        Login: formatDateTime(user.punches[0]?.time, "time"),
        Logout: formatDateTime(lastPunchOut?.time, "time"),
        Production: user.Production,
        Break: minutesToHoursFormatted(user.breaks),
        Overtime: user.overtime,
      };
    }) || [];

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      dataIndex: "Date",
      sorter: (a, b) => a.Date.localeCompare(b.Date),
    },
    {
      title: "Login",
      dataIndex: "Login",
      sorter: (a, b) => a.Login.localeCompare(b.Login),
    },
    {
      title: "Logout",
      dataIndex: "Logout",
      sorter: (a, b) => a.Logout.localeCompare(b.Logout),
    },
    {
      title: "Break",
      dataIndex: "Break",
      sorter: (a, b) => a.Break.localeCompare(b.Break),
    },
    {
      title: "Overtime",
      dataIndex: "Overtime",
      sorter: (a, b) => a.Overtime.localeCompare(b.Overtime),
    },
  ];

  const handlePlayStop = async () => {
    if (isRunning) {
      clearInterval(timerRef.current);
      setIsRunning(false);
      // setPunchType("out");

      const punchOutData = {
        userId: userId,
      };

      const data = await postPunchOut(punchOutData);
      if (data) {
        setLoadData(!loadData);
      }
    } else {
      timerRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      setIsRunning(true);
      // setPunchType("in");

      const punchInData = {
        userId: userId,
      };

      const data = await postPunchIn(punchInData);
      if (data) {
        setLoadData(!loadData);
      }
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  function minutesToHoursFormatted(minutes) {
    const seconds = minutes * 60;
    const hours = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, "0")}:${min
      .toString()
      .padStart(2, "0")}`;
  }

  if (loading) {
    return (
      <div className="loading-effect">
        <Loading />
      </div>
    );
  }
  // if (error) {
  //   return <Error404 />;
  // }

  return (
    <>
      <div className="page-wrapper">
        {/* /Page Header */}
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Attendance"
            title="Dashboard"
            subtitle="Attendance"
            modalClass="col-auto float-end ms-auto"
          />

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-4">
              <div className="card punch-status">
                <div className="card-body">
                  <h5 className="card-title">
                    Timesheet{" "}
                    <small className="text-muted">
                      {formatDateTime(
                        apiData?.data?.attendances[lastAttendanceIndex]?.date,
                        "date"
                      )}
                    </small>
                  </h5>
                  <div className="punch-det">
                    <h6>Punch In at</h6>
                    <p>{formatDateTime(lastPunchTime, "datetime")}</p>
                  </div>
                  <div className="punch-info">
                    <div className="punch-hours">
                      <span>
                        {" "}
                        {isRunning
                          ? formatTime(time)
                          : minutesToHoursFormatted(
                              apiData?.data?.attendances[lastAttendanceIndex]
                                ?.totalHours
                            )}
                      </span>
                    </div>
                  </div>
                  <div className="punch-btn-section">
                    <button
                      type="button"
                      className="btn btn-primary punch-btn"
                      disabled={!initialVal}
                      onClick={handlePlayStop}
                    >
                      {isRunning ? "Punch Out" : "Punch In"}
                    </button>
                  </div>
                  <div className="statistics">
                    <div className="row">
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Break</p>
                          <h6>
                            {minutesToHoursFormatted(
                              apiData?.data?.attendances[lastAttendanceIndex]
                                ?.breaks
                            )}
                            hr
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Overtime</p>
                          <h6>
                            {
                              apiData?.data?.attendances[lastAttendanceIndex]
                                ?.overtime
                            }
                            hr
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card att-statistics">
                <div className="card-body">
                  <h5 className="card-title">Statistics</h5>
                  <div className="stats-list">
                    <div className="stats-info">
                      <p>
                        Today
                        <strong>
                          {minutesToHoursFormatted(
                            apiData?.data?.attendances[lastAttendanceIndex]
                              ?.totalHours
                          )}{" "}
                          <small>/ 8 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-primary`}
                          role="progressbar"
                          style={{ width: "31%" }}
                          aria-valuenow={"31%"}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="stats-info">
                      <p>
                        This Week
                        <strong>
                          {
                            apiData?.data?.attendances[lastAttendanceIndex]
                              ?.weeklyTotalHours
                          }{" "}
                          <small>/ 40 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-warning`}
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow={"10%"}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="stats-info">
                      <p>
                        This Month
                        <strong>
                          {
                            apiData?.data?.attendances[lastAttendanceIndex]
                              ?.monthlyTotalHours
                          }{" "}
                          <small>/ 160 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-danger`}
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow={"10%"}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="stats-info">
                      <p>
                        Overtime
                        <strong>
                          {
                            apiData?.data?.attendances[lastAttendanceIndex]
                              ?.monthlyOvertime
                          }
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className={`progress-bar bg-info`}
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow={"10%"}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card recent-activity">
                <div className="card-body">
                  <h5 className="card-title">Today Activity</h5>
                  <ul className="res-activity-list">
                    <div className="res-activity-list-inside position-relative">
                      {Array.isArray(punches) && punches.length > 0 ? (
                        punches.map((item, index) => (
                          <li key={index}>
                            <p className="mb-0">Punch {item.type}</p>
                            <p className="res-activity-time">
                              <i className="fa-regular fa-clock"></i>{" "}
                              {formatDateTime(item?.time, "time")}
                              {/* {item?.time} */}
                            </p>
                          </li>
                        ))
                      ) : (
                        <p>No activities available.</p>
                      )}
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <AttendanceEmployeeFilter
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              onSearch={filterAttendances}
            />
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={userElements?.length > 0 ? userElements : []}
                    className="table-striped"
                    rowKey={(record) => record.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceEmployee;
