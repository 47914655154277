import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Avatar_07,
  Avatar_13,
  Avatar_14,
  Avatar_17,
  Avatar_18,
  Avatar_19,
  Avatar_28,
  Avatar_29,
} from "../../../Routes/ImagePath";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ClientsFilter from "../../../components/ClientsFilter";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import { ClientModelPopup } from "../../../components/modelpopup/ClientModelPopup";

const Clients = () => {
  const [inputValueClientId, setInputValueClientId] = useState("");
  const [inputValueEmployeeName, setInputValueEmployeeName] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);

  const clientsData = [
    {
      id: 1,
      avatar: Avatar_19,
      companyName: "Global Tech",
      contactName: "Barry Cuda",
      position: "CEO",
    },
    {
      id: 2,
      avatar: Avatar_29,
      companyName: "Delta Infotech",
      contactName: "Tressa Wexler",
      position: "Manager",
    },
    {
      id: 3,
      avatar: Avatar_07,
      companyName: "Ruby Bartlett",
      contactName: "Cream Inc",
      position: "CEO",
    },
    {
      id: 4,
      avatar: Avatar_14,
      companyName: "Wellware Company",
      contactName: "Misty Tison",
      position: "CEO",
    },
    {
      id: 5,
      avatar: Avatar_18,
      companyName: "Mustang Technologies",
      contactName: "Daniel Deacon",
      position: "CEO",
    },
    {
      id: 6,
      avatar: Avatar_17,
      companyName: "International Software Inc",
      contactName: "Walter Weaver",
      position: "CEO",
    },
    {
      id: 7,
      avatar: Avatar_28,
      companyName: "Mercury Software Inc",
      contactName: "Amanda Warren",
      position: "CEO",
    },
    {
      id: 8,
      avatar: Avatar_13,
      companyName: "International Software Inc",
      contactName: "Betty Carlson",
      position: "CEO",
    },
  ];

  // Define the available company options
  const companyOptions = [
    { value: "Global Tech", label: "Global Tech" },
    { value: "Delta Infotech", label: "Delta Infotech" },
    { value: "Ruby Bartlett", label: "Ruby Bartlett" },
    { value: "Wellware Company", label: "Wellware Company" },
    { value: "Mustang Technologies", label: "Mustang Technologies" },
    {
      value: "International Software Inc",
      label: "International Software Inc",
    },
    { value: "Mercury Software Inc", label: "Mercury Software Inc" },
  ];

  // Filter clients data based on input values
  const filteredClients = clientsData.filter((client) => {
    const matchesClientId = client.id.toString().includes(inputValueClientId);
    const matchesEmployeeName = client.contactName
      .toLowerCase()
      .includes(inputValueEmployeeName.toLowerCase());
    const matchesCompany = selectedCompany
      ? client.companyName === selectedCompany.value
      : true;

    return matchesClientId && matchesEmployeeName && matchesCompany;
  });

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Clients"
            title="Dashboard"
            subtitle="Clients"
            modal="#add_client"
            name="Add Client"
            Linkname="/clients"
            Linkname1="/clients-list"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div
                className={`input-block mb-3 form-focus ${
                  inputValueClientId ? "focused" : ""
                }`}
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueClientId}
                  onChange={(e) => setInputValueClientId(e.target.value)}
                />
                <label className="focus-label">Client ID</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div
                className={`input-block mb-3 form-focus ${
                  inputValueEmployeeName ? "focused" : ""
                }`}
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueEmployeeName}
                  onChange={(e) => setInputValueEmployeeName(e.target.value)}
                />
                <label className="focus-label">Employee Name</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Company"
                  value={selectedCompany}
                  onChange={setSelectedCompany}
                  options={companyOptions}
                />
                <label className="focus-label">Company</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <Link to="#" className="btn btn-success w-100">
                Search
              </Link>
            </div>
          </div>

          <div className="row staff-grid-row">
            {filteredClients.map((client) => (
              <div
                key={client.id}
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to="/client-profile" className="avatar">
                      <img alt="" src={client.avatar} />
                    </Link>
                  </div>
                  <div className="dropdown profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_client"
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                      >
                        <i className="fa fa-trash m-r-5" /> Delete
                      </Link>
                    </div>
                  </div>
                  <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to="/client-profile">{client.companyName}</Link>
                  </h4>
                  <h5 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to="/client-profile">{client.contactName}</Link>
                  </h5>
                  <div className="small text-muted">{client.position}</div>
                  <Link
                    onClick={() => localStorage.setItem("minheight", "true")}
                    to="/call/chat"
                    className="btn btn-white btn-sm m-t-10 me-1"
                  >
                    Message
                  </Link>
                  <Link
                    to="/client-profile"
                    className="btn btn-white btn-sm m-t-10"
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ClientModelPopup />
      <DeleteModal Name="Delete Client" />
    </div>
  );
};

export default Clients;
