// employeeSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://zolara-crm-backend.vercel.app/api"; // Adjust the base URL as needed
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZWI1ODFiNGMzZjFmYWNkOTU3MzFiMSIsImlhdCI6MTcyNjcwMjI3Nn0.fySf256HQbh6nAysbxNYUML-1JvHpx7pfn1JEWIj7Fk";

// Fetch all employees
export const fetchEmployees = createAsyncThunk(
  "employee/fetchEmployees",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken; // Access token from the Redux store
    const response = await axios.get(`${apiUrl}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data?.data?.users;
  }
);

// Update employee status
export const updateEmployeeStatus = createAsyncThunk(
  "employee/updateEmployeeStatus",
  async (employee, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.patch(
      `${apiUrl}/users/${employee._id}`,
      {
        status: employee.status,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response?.data?.data?.user;
  }
);

// Update an existing employee
export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const { id, ...data } = employeeData;
    const response = await axios.patch(`${apiUrl}/users/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data?.data?.user;
  }
);

// Delete an employee
export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (employeeId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/users/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return employeeId;
  }
);

// Fetch all departments
export const fetchDepartments = createAsyncThunk(
  "employee/fetchDepartments",
  async (thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.get(`${apiUrl}/departments`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.departments;
  }
);

export const updateDepartment = createAsyncThunk(
  "employee/updateDepartments",
  async ({ departmentId, description, }, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.patch(
      `${apiUrl}/departments/${departmentId}`,
      { description },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.departments;
  }
);

export const deleteDepartment = createAsyncThunk(
  "employee/deleteDepartments",
  async (departmentId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.delete(
      `${apiUrl}/departments/${departmentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.departments;
  }
);

// Fetch designations for a specific department
export const fetchDesignations = createAsyncThunk(
  "employee/fetchDesignations",
  async (departmentId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.get(
      `${apiUrl}/designations/department/${departmentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.designations;
  }
);
export const updateDesignations = createAsyncThunk(
  "employee/updateDesignations",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const { id, ...data } = employeeData;
    const response = await axios.patch(`${apiUrl}/designations/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response?.data?.data?.user;
  }
);

// Delete an employee
export const deleteDesignations = createAsyncThunk(
  "employee/deleteDesignations",
  async (designationId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/designations/${designationId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return designationId;
  }
);

// Delete an estimate
export const deleteEstimates = createAsyncThunk(
  "employee/deleteEstimates",
  async (estimateId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/sales/${estimateId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return estimateId;
  }
);
export const deleteSaleFieldOption = createAsyncThunk(
  "employee/deleteSaleFieldOption",
  async (optionId, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    await axios.delete(`${apiUrl}/fields/${optionId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return optionId;
  }
);

// Add a new employee
export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (employeeData, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.userToken;
    const response = await axios.post(`${apiUrl}/users`, employeeData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.user;
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employees: [],
    departments: [],
    designations: [],
    estimates: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setDesignations: (state, action) => {
      state.designations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employees = action.payload;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.departments = action.payload;
      })
      .addCase(fetchDesignations.fulfilled, (state, action) => {
        state.designations = action.payload;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.employees.push(action.payload);
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        const index = state.employees.findIndex(
          (emp) => emp._id === action.payload._id
        );
        if (index !== -1) {
          state.employees[index] = action.payload;
        }
      })
      .addCase(updateEmployeeStatus.fulfilled, (state, action) => {
        const index = state.employees.findIndex(
          (emp) => emp._id === action.payload._id
        );
        if (index !== -1) {
          state.employees[index].status = action.payload.status;
        }
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.employees = state.employees.filter(
          (emp) => emp._id !== action.payload
        );
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.departments = state.departments.filter(
          (dept) => dept._id !== action.payload
        );
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        const index = state.departments.findIndex(
          (dept) => dept._id === action.payload._id
        );
      })
      .addCase(deleteDesignations.fulfilled, (state, action) => {
        state.departments = state.departments.filter(
          (dept) => dept._id !== action.payload
        );
      })
      .addCase(deleteEstimates.fulfilled, (state, action) => {
        state.departments = state.departments.filter(
          (dept) => dept._id !== action.payload
        );
      })
      .addCase(deleteSaleFieldOption.fulfilled, (state, action) => {
        state.departments = state.departments.filter(
          (dept) => dept._id !== action.payload
        );
      })
      .addCase(updateDesignations.fulfilled, (state, action) => {
        const index = state.departments.findIndex(
          (dept) => dept._id === action.payload._id
        );
      });
  },
});

export const { setDesignations } = employeeSlice.actions;
export default employeeSlice.reducer;
// if (index !== -1) {
//   state.departments[index] = action.payload;
// }
