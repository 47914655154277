import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import EditSalesFormTable from "./EditSalesFormTable";
const EditSalesForm = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="SalesFormFields"
            title="Dashboard"
            subtitle="SalesFormFields"
            modalClass="d-none col-auto float-end ms-auto"
          />

          <div className="row">
            <div className="col-md-12"></div>
          </div>
          <EditSalesFormTable />
        </div>
      </div>
    </div>
  );
};

export default EditSalesForm;
