import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import {
  fetchEmployees,
  deleteEmployee,
  updateEmployeeStatus,
} from "../../../store/slices/EmployeeSlice";
import { User } from "../../../Routes/ImagePath";

const AllEmployee = () => {
  const dispatch = useDispatch();
  const { employees = [], status } = useSelector((state) => state?.employee);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  useEffect(() => {
    setFilteredEmployees(employees);
  }, [employees]);

  const handleFilterChange = ({ employeeId, employeeName, department }) => {
    const filtered = employees.filter((employee) => {
      const matchesId = employeeId
        ? employee.employeeId?.toString().includes(employeeId) || false
        : true;

      const matchesName = employeeName
        ? (
            (employee.firstName?.toLowerCase() || "") +
            (employee.lastName?.toLowerCase() || "")
          ).includes(employeeName.toLowerCase())
        : true;
      const matchesDepartment =
        !department || department.label === "All"
          ? true
          : employee?.department &&
            employee?.department?._id === department?.value;
      return matchesId && matchesName && matchesDepartment;
    });

    setFilteredEmployees(filtered);
  };

  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    if (employeeToDelete) {
      dispatch(deleteEmployee(employeeToDelete._id));
    }
    setShowDeleteModal(false);
    setEmployeeToDelete(null);
  };

  const handleStatusChange = (employee, status) => {
    dispatch(updateEmployeeStatus({ _id: employee._id, status }));
  };

  const renderActionButton = (employee) => {
    if (employee.status === "active") {
      return (
        <div className="d-flex flex-column">
          <Link
            className="dropdown-item"
            onClick={() => handleStatusChange(employee, "disabled")}
          >
            <i className="fa fa-ban m-r-5" /> Disable
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => handleDeleteClick(employee)}
          >
            <i className="fa fa-trash m-r-5" /> Delete
          </Link>
        </div>
      );
    } else if (employee.status === "disabled") {
      return (
        <>
          <Link
            className="dropdown-item"
            onClick={() => handleStatusChange(employee, "active")}
          >
            <i className="fa fa-toggle-on m-r-5" />
            Activate
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => handleDeleteClick(employee)}
          >
            <i className="fa fa-trash m-r-5" /> Delete
          </Link>
        </>
      );
    }
  };

  const renderEmployeeCard = (employee) => {
    const isDisabled = employee.status === "disabled";
    return (
      <div
        className={`col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 ${
          isDisabled ? "disabled-card" : ""
        }`}
        key={employee._id}
        style={{
          opacity: isDisabled ? 0.6 : 1,
          borderColor: isDisabled ? "#dc3545" : "#ccc",
        }}
      >
        <div className="profile-widget">
          <div className="profile-img">
            <Link className="avatar">
              {employee.image ? (
                <img
                  src={employee.image}
                  alt={employee.firstName + employee.lastName}
                />
              ) : (
                <img src={User} alt={employee.firstName + employee.lastName} />
              )}
            </Link>
          </div>
          <div className="dropdown profile-action">
            <Link
              to="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              {renderActionButton(employee)}
            </div>
          </div>
          <h4 className="user-name m-t-10 mb-0 text-ellipsis">
            <Link to="#">{employee.firstName + " " + employee.lastName}</Link>
          </h4>
          <div className="small text-muted">{employee.role}</div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (status === "loading") {
      return (
        <div className="text-center">
          <Loading />
        </div>
      );
    }

    if (status === "failed") {
      return (
        <div className="text-center text-danger">
          <Error404 />
        </div>
      );
    }

    if (filteredEmployees.length === 0) {
      return <div className="text-center">No employees found</div>;
    }

    return filteredEmployees.map((employee) => renderEmployeeCard(employee));
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            modalClass="col-auto float-end ms-auto"
          />
          <EmployeeListFilter onFilterChange={handleFilterChange} />

          <div className="row">{renderContent()}</div>
        </div>
      </div>

      <AllEmployeeAddPopup
        employee={employeeToDelete}
        setEmployee={setEmployeeToDelete}
      />
      <DeleteModal
        show={showDeleteModal}
        onDeleteLeave={handleDeleteConfirm}
        closeModal={() => setShowDeleteModal(false)}
        entityName="employee"
      />
    </div>
  );
};

export default AllEmployee;
