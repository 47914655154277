import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import UsePostData from "../../../../../hooks/api/PostHook";
import useFetch from "../../../../../hooks/api/fetchHook";
import { useSelector } from "react-redux";

const AddEditPage = () => {
  const [formData, setFormData] = useState({
    date: "",
    accountName: "",
    servicesAddress: "",
    billingAddress: "",
    primaryContact: "",
    altContact: "",
    purposeOfCall: "",
    currentProvider: "",
    accountType: "",
    accountBalance: "",
    pastDue: "",
    accountNumber: "",
    secuirityCode: "",
    dueDate: "",
    cardType: "",
    fourDigits: "",
    authorized: "",
    qualityControl: "",
    status: "",
    paymentProcessor: "",
    paidOver: "",
    callBack: "",
    paymentConfirmationNumber: "",
    billingTeamComments: "",
    followUpTeamComments: "",
  });

  const [isBillingSame, setIsBillingSame] = useState(false);
  const [options, setOptions] = useState({});

  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state?.auth);

  const { postData } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/sales"
  );

  const fetchOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "servicesAddress" && isBillingSame) {
      setFormData((prevData) => ({
        ...prevData,
        billingAddress: value,
      }));
    }
  };

  const { apiData, isLoading, isError } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/fields",
    fetchOptions
  );

  const inputData = [
    {
      type: "date",
      status: "inputs",
      label: "Date",
      name: "date",
    },
    {
      type: "text",
      status: "inputs",
      label: "Account Name",
      name: "accountName",
    },
    {
      type: "text",
      status: "inputs",
      label: "Services Address",
      name: "servicesAddress",
    },
    {
      type: "text",
      status: "inputs",
      label: "Billing Address",
      name: "billingAddress",
    },
    {
      type: "number",
      status: "inputs",
      label: "Primary Contact",
      name: "primaryContact",
    },
    {
      type: "number",
      status: "inputs",
      label: "Alt Contact",
      name: "altContact",
    },
    {
      status: "selectors",
      label: "Purpose Of Call",
      name: "purposeOfCall",
      optionValue: "PurposeOfCall",
    },
    {
      status: "selectors",
      label: "Current Provider",
      name: "currentProvider",
      optionValue: "CurrentProvider",
    },
    {
      status: "selectors",
      label: "Account Type",
      name: "accountType",
      optionValue: "AccountType",
    },
    {
      type: "number",
      status: "inputs",
      label: "Account Balance",
      name: "accountBalance",
    },
    {
      type: "number",
      status: "inputs",
      label: "Past Due",
      name: "pastDue",
    },
    {
      type: "number",
      status: "inputs",
      label: "Account Number",
      name: "accountNumber",
    },
    {
      type: "number",
      status: "inputs",
      label: "Secuirity Code",
      name: "secuirityCode",
    },
    {
      type: "date",
      status: "inputs",
      label: "Due Date",
      name: "dueDate",
    },
    {
      status: "selectors",
      label: "Card Type",
      name: "cardType",
      optionValue: "CardType",
    },
    {
      type: "number",
      status: "inputs",
      label: "Last 4 Digits",
      name: "fourDigits",
    },
    {
      type: "text",
      status: "inputs",
      label: "Authorized",
      name: "authorized",
    },
    {
      status: "selectors",
      label: "Quality Control",
      name: "qualityControl",
      optionValue: "QualityControl",
    },
    {
      status: "selectors",
      label: "Status",
      name: "status",
      optionValue: "Status",
    },
    {
      status: "selectors",
      label: "Payment Processor",
      name: "paymentProcessor",
      optionValue: "PaymentProcessor",
    },
    {
      status: "selectors",
      label: "Paid Over",
      name: "paidOver",
      optionValue: "PaidOver",
    },
    {
      status: "selectors",
      label: "Call Back",
      name: "callBack",
      optionValue: "CallBack",
    },
    {
      type: "number",
      status: "inputs",
      label: "Payment Confirmation Number",
      name: "paymentConfirmationNumber",
    },
    {
      type: "text",
      status: "inputs",
      label: "Billing Team Comments",
      name: "billingTeamComments",
    },
    {
      type: "text",
      status: "inputs",
      label: "Follow up Team Comments",
      name: "followUpTeamComments",
    },
  ];

  useEffect(() => {
    if (apiData?.data) {
      const filteredOptions = inputData
        .filter((item) => item.status === "selectors" && item.optionValue)
        .map((item) => ({
          name: item.name,
          options: apiData.data
            .filter((field) => field.type === item.optionValue)
            .map((field) => ({ value: field._id, label: field.name })),
        }));

      const updatedOptions = filteredOptions.reduce((acc, curr) => {
        acc[curr.name] = curr.options;
        return acc;
      }, {});

      setOptions(updatedOptions);
    }
  }, [apiData, inputData]);

  const handleChange = (selectedOption, { name }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption?.label || "", // Update to store label
    }));
  };

  const handleSubmit = async () => {
    const finalData = {
      employeeId: "66d8a55b196ff72f48e2df92",
      ...formData,
    };

    const result = await postData(finalData);
    if (result?.status === "success") {
      console.log("Form submitted successfully");
      navigate("/sales");
      toast.success("Estimate Created Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    } else {
      console.error("Form submission failed:", result?.message);
    }
  };

  const handleCheckboxChange = () => {
    setIsBillingSame(!isBillingSame);
    if (!isBillingSame) {
      setFormData((prevData) => ({
        ...prevData,
        billingAddress: formData.servicesAddress,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        billingAddress: "",
      }));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div className="row d-flex">
              {inputData.map((item, index) => (
                <div key={index} className="col-md-3 col-sm-6">
                  <div className="input-block mb-3">
                    {item.status === "inputs" ? (
                      <div>
                        <label htmlFor={item.name} className="col-form-label">
                          {item.label}
                        </label>
                        <input
                          type={item.type}
                          className="form-control"
                          name={item.name}
                          value={formData[item.name] || ""}
                          onChange={handleInputChange}
                          disabled={
                            item.name === "billingAddress" && isBillingSame
                          }
                        />
                        {item.name === "billingAddress" && (
                          <div className="mt-2">
                            <input
                              type="checkbox"
                              id="billingCheckbox"
                              checked={isBillingSame}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="billingCheckbox" className="ms-2">
                              Same as Services Address
                            </label>
                          </div>
                        )}
                      </div>
                    ) : item.status === "selectors" ? (
                      <div>
                        <label
                          htmlFor={item.name}
                          className="pb-1 col-form-label"
                        >
                          {item.label}
                        </label>
                        <div className="custom-select-container">
                          <Select
                            options={options[item.name] || []}
                            value={options[item.name]?.find(
                              (option) => option.label === formData[item.name]
                            )}
                            onChange={(selectedOption) =>
                              handleChange(selectedOption, item)
                            }
                            className="form-control p-0"
                            name={item.name}
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: "38px",
                              }),
                              menu: (base) => ({
                                ...base,
                                width: "100%",
                              }),
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="submit-section">
            <button
              className="btn btn-primary submit-btn m-r-10"
              onClick={handleSubmit}
            >
              Save &amp; Send
            </button>
            <button
              className="btn btn-primary submit-btn"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditPage;
