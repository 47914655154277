import React, { useState } from "react";
import { Pagination, Table } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import useFetch from "../../../../../hooks/api/fetchHook";
import Loading from "../../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../../hooks/utils/Error/Error";
import { deleteEstimates } from "../../../../../store/slices/EmployeeSlice";

const EstimatesTable = ({
  filterFrom,
  filterTo,
  filterDueDate,
  filterStatus,
  filterSalesAssociate,
}) => {
  const { userToken } = useSelector((state) => state?.auth);
  const [selectedEstimate, setSelectedEstimate] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const dispatch = useDispatch();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, isLoading, isError } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/sales",
    options
  );

  const salesData = apiData?.data || [];

  const getMatchingKey = (sale, targetValue) => {
    for (const key in sale) {
      if (sale[key] === targetValue) {
        return key;
      }
    }
    return null;
  };

  const filteredData = salesData.filter((sale) => {
    const saleDate = new Date(sale.createdAt).toLocaleDateString();
    const saleToDate = new Date(sale.updatedAt).toLocaleDateString();
    const dueDate = new Date(sale.dueDate).toLocaleDateString();

    const dateMatch =
      (!filterFrom || new Date(saleDate) >= new Date(filterFrom)) &&
      (!filterTo || new Date(saleToDate) <= new Date(filterTo));

    const saleKey = getMatchingKey(sale, filterStatus);

    const statusMatch = !filterStatus || sale[saleKey] === filterStatus;

    const dueDateMatch =
      !filterDueDate || new Date(dueDate) <= new Date(filterDueDate);

    const salesAssociateMatch =
      !filterSalesAssociate ||
      sale.employeeId?.firstName
        .toLowerCase()
        .includes(filterSalesAssociate.toLowerCase());

    return dateMatch && statusMatch && dueDateMatch && salesAssociateMatch;
  });

  const salesElements = filteredData.map((sale, index) => ({
    key: index,
    id: sale._id,
    date: new Date(sale.createdAt).toLocaleDateString() || "N/A",
    salesAssociate: sale.employeeId ? `${sale.employeeId.firstName}` : "N/A",
    accountName: sale.accountName || "N/A",
    servicesAddress: sale.servicesAddress || "N/A",
    billingAddress: sale.billingAddress || "N/A",
    primaryContact: sale.primaryContact || "N/A",
    altContact: sale.altContact || "N/A",
    purposeOfCall: sale.purposeOfCall || "N/A",
    currentProvider: sale.currentProvider || "N/A",
    accountType: sale.accountType || "N/A",
    accountBalance: sale.accountBalance || "N/A",
    pastDue: sale.pastDue || "N/A",
    accountNumber: sale.accountNumber || "N/A",
    securityCode: sale.securityCode || "N/A",
    dueDate: new Date(sale.dueDate).toLocaleDateString() || "N/A",
    cardType: sale.cardType || "N/A",
    last4Digits: sale.accountNumber ? sale.accountNumber.slice(-4) : "N/A",
    authorized: sale.authorized || "N/A",
    qualityControl: sale.qualityControl || "N/A",
    paymentProcessor: sale.paymentProcessor || "N/A",
    paidOver: sale.paidOver || "N/A",
    callBack: sale.callBack || "N/A",
    payment: sale.payment || "N/A",
    confirmationNumber: sale.paymentConfirmationNumber || "N/A",
    billingTeamComments: sale.billingTeamComments || "N/A",
    followUpComments: sale.followUpTeamComments || "N/A",
    status: sale.status || "N/A",
  }));

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <Link to="/estimatesview">{text}</Link>,
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Sales Associate(s)",
      dataIndex: "salesAssociate",
      sorter: (a, b) => a.salesAssociate.localeCompare(b.salesAssociate),
    },
    {
      title: "Account Name",
      dataIndex: "accountName",
      sorter: (a, b) => a.accountName.localeCompare(b.accountName),
    },
    {
      title: "Services Address",
      dataIndex: "servicesAddress",
      sorter: (a, b) => a.servicesAddress.localeCompare(b.servicesAddress),
    },
    {
      title: "Billing Address",
      dataIndex: "billingAddress",
    },
    {
      title: "Primary Contact",
      dataIndex: "primaryContact",
    },
    {
      title: "Alt Contact",
      dataIndex: "altContact",
    },
    {
      title: "Purpose of Call",
      dataIndex: "purposeOfCall",
    },
    {
      title: "Current Provider",
      dataIndex: "currentProvider",
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
    },
    {
      title: "Account Balance",
      dataIndex: "accountBalance",
    },
    {
      title: "Past Due",
      dataIndex: "pastDue",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
    },
    {
      title: "Security Code",
      dataIndex: "securityCode",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
    {
      title: "Card Type",
      dataIndex: "cardType",
    },
    {
      title: "Last 4 Digits",
      dataIndex: "last4Digits",
    },
    {
      title: "Quality Control",
      dataIndex: "qualityControl",
    },
    {
      title: "Payment Processor",
      dataIndex: "paymentProcessor",
    },
    {
      title: "Paid Over",
      dataIndex: "paidOver",
    },
    {
      title: "Call Back",
      dataIndex: "callBack",
    },
    {
      title: "Confirmation Number",
      dataIndex: "confirmationNumber",
    },
    {
      title: "Billing Team Comments",
      dataIndex: "billingTeamComments",
    },
    {
      title: "Follow Up Comments",
      dataIndex: "followUpComments",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={
            text === "Accepted"
              ? "badge bg-inverse-success"
              : text === "Declined"
              ? "badge bg-inverse-danger"
              : text === "Sent"
              ? "badge bg-inverse-info"
              : text === "Expired"
              ? "badge bg-inverse-warning"
              : "badge bg-inverse-default"
          }
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#edit_designation"
              // onClick={() => handleEditEstimate(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteEstimate(record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const handleDeleteEstimate = (estimate) => {
    setSelectedEstimate(estimate);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (selectedEstimate) {
      dispatch(deleteEstimates(selectedEstimate.id));
      setShowDeleteModal(false);
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={isLoading || isError ? [] : salesElements}
            rowKey="id"
            className="table table-striped custom-table mb-0"
            locale={{
              emptyText: isLoading ? (
                <Loading />
              ) : isError ? (
                <Error404 />
              ) : (
                "No Data"
              ),
            }}
            pagination={false}
          />
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={salesElements.length}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
          showSizeChanger
          pageSizeOptions={["10", "25", "50", "100"]}
          style={{ marginTop: "20px", textAlign: "right" }}
        />
      </div>
      <DeleteModal
        Name="Delete Estimate"
        closeModal={closeDeleteModal}
        onDeleteLeave={handleDelete}
      />
    </div>
  );
};

export default EstimatesTable;
