import React, { useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import useFetch from "../../../../../hooks/api/fetchHook";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import EstimatesTable from "./estimatesTable";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";

const Estimates = () => {
  const { userToken } = useSelector((state) => state?.auth);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTwo, setSelectedDateTwo] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [salesAssociate, setSalesAssociate] = useState("");
  const [focused, setFocused] = useState(false);

  const [filterFrom, setFilterFrom] = useState(null);
  const [filterTo, setFilterTo] = useState(null);
  const [filterDueDate, setFilterDueDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterSalesAssociate, setFilterSalesAssociate] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateChangeTwo = (date) => {
    setSelectedDateTwo(date);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/fields",
    options
  );

  const typeOptions = apiData?.data
    ? Array.from(new Set(apiData.data.map((item) => item.type))).map(
        (type) => ({
          value: type,
          label: type,
        })
      )
    : [];

  const nameOptions = selectedType
    ? apiData?.data
        .filter((item) => item.type === selectedType.value)
        .map((item) => ({ value: item.name, label: item.name }))
    : [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "50px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#ff9b44" : provided.borderColor,
      paddingTop: "10px",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleSearch = () => {
    setFilterFrom(selectedDate);
    setFilterTo(selectedDateTwo);
    setFilterDueDate(dueDate);
    setFilterStatus(selectedOption ? selectedOption.value : null);
    setFilterSalesAssociate(salesAssociate);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Sales"
            title="Dashboard"
            subtitle="Sales"
            modal="#"
            name="Create Sales"
            link="/create-sale"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  selectedDate ? "focused" : ""
                }`}
              >
                <div className="cal-icon focused">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  selectedDateTwo ? "focused" : ""
                }`}
              >
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={selectedDateTwo}
                    onChange={handleDateChangeTwo}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus ${
                  dueDate ? "focused" : ""
                }`}
              >
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={dueDate}
                    onChange={handleDueDateChange}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <label className="focus-label">Due Date</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Type"
                  onChange={setSelectedType}
                  options={typeOptions}
                  styles={customStyles}
                />
                <label className="focus-label">Type</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Option"
                  onChange={setSelectedOption}
                  options={nameOptions}
                  styles={customStyles}
                  isDisabled={!selectedType}
                />
                <label className="focus-label">Options</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={
                  focused || salesAssociate !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={salesAssociate}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(salesAssociate !== "")}
                  onChange={(e) => setSalesAssociate(e.target.value)}
                />
                <label className="focus-label" onClick={() => setFocused(true)}>
                  Sales Associates
                </label>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <Link
                className="btn btn-success btn-block w-100"
                onClick={handleSearch}
              >
                Search
              </Link>
            </div>
          </div>

          <EstimatesTable
            filterFrom={filterFrom}
            filterTo={filterTo}
            filterDueDate={filterDueDate}
            filterStatus={filterStatus}
            filterSalesAssociate={filterSalesAssociate}
          />
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" />
    </>
  );
};

export default Estimates;
