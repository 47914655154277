import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useFetch from "../../../hooks/api/fetchHook";
import { Avatar_09 } from "../../../Routes/ImagePath";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import Select from "react-select";

const TableAvatar = () => {
  const userToken = useSelector((state) => state?.auth?.userToken);
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, isLoading, isError } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/users/",
    options
  );

  const [users, setUsers] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [searchName, setSearchName] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [focused, setFocused] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rowsPerPageOptions = [
    { value: 10, label: "10 / Page" },
    { value: 25, label: "25 / Page" },
    { value: 50, label: "50 / Page" },
    { value: 100, label: "100 / Page" },
  ];

  const monthOptions = [
    { value: null, label: "Select Month" },
    ...Array.from({ length: 12 }, (_, i) => ({
      value: i + 1,
      label: new Date(0, i).toLocaleString("default", { month: "long" }),
    })),
  ];

  const yearOptions = [
    { value: null, label: "Select Year" },
    ...Array.from({ length: 10 }, (_, i) => ({
      value: 2020 + i,
      label: (2020 + i).toString(),
    })),
  ];

  useEffect(() => {
    if (apiData && apiData.data.users && apiData.data.users.length > 0) {
      setUsers(apiData.data.users);

      const currentAttendance = {};
      apiData.data.users.forEach((user) => {
        const userId = user._id;
        currentAttendance[userId] = {};

        user.attendance.forEach((entry) => {
          const date = new Date(entry.date).toLocaleDateString("en-GB");
          currentAttendance[userId][date] = entry;
        });
      });

      setAttendance(currentAttendance);
    }
  }, [apiData]);

  const getFormattedDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString("en-GB");
  };

  const handleShowAttendance = (userId, dateKey) => {
    setSelectedAttendance(attendance[userId][dateKey]);
  };

  const handleSearch = () => {
    if (!searchMonth || !searchYear) {
      alert("Please select a valid month and year");
      return;
    }

    const filtered = users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`;
      const matchName = fullName
        .toLowerCase()
        .includes(searchName.toLowerCase());

      const hasAttendanceInMonthYear = user.attendance.some((entry) => {
        const entryDate = new Date(entry.date);
        const entryMonth = entryDate.getMonth() + 1;
        const entryYear = entryDate.getFullYear();
        return (
          entryMonth === parseInt(searchMonth) &&
          entryYear === parseInt(searchYear)
        );
      });

      return matchName && hasAttendanceInMonthYear;
    });

    setFilteredUsers(filtered);
  };

  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = (filteredUsers.length > 0 ? filteredUsers : users).slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  const totalPages = Math.ceil(
    (filteredUsers.length > 0 ? filteredUsers.length : users.length) /
      rowsPerPage
  );

  const changeRowsPerPage = (selectedOption) => {
    setRowsPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focused || searchName !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={searchName}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(searchName !== "")}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <label className="focus-label" onClick={() => setFocused(true)}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={monthOptions}
              placeholder="Select Month"
              onChange={(selectedOption) =>
                setSearchMonth(selectedOption?.value || "")
              }
              value={monthOptions.find(
                (option) => option.value === searchMonth
              )}
            />
            <label className="focus-label">Select Month</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={yearOptions}
              placeholder="Select Year"
              onChange={(selectedOption) =>
                setSearchYear(selectedOption?.value || "")
              }
              value={yearOptions.find((option) => option.value === searchYear)}
            />
            <label className="focus-label">Select Year</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <Link
            className="btn btn-success btn-block w-100"
            onClick={handleSearch}
          >
            Search
          </Link>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped custom-table table-nowrap mb-0">
          <thead>
            <tr>
              <th>Employee</th>
              {Array.from({ length: 31 }, (_, i) => (
                <th key={i + 1}>{i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, index) => (
              <tr key={index}>
                <td>
                  <h2 className="table-avatar">
                    <Link className="avatar avatar-xs" to="/profile">
                      <img src={user.image ? user.image : Avatar_09} alt="" />
                    </Link>
                    <Link to="/profile">
                      {user.firstName} {user.lastName}
                    </Link>
                  </h2>
                </td>
                {Array.from({ length: 31 }, (_, i) => {
                  const dateKey = getFormattedDate(
                    i + 1,
                    searchMonth || new Date().getMonth() + 1,
                    searchYear || new Date().getFullYear()
                  );
                  return (
                    <td key={i + 1}>
                      {attendance[user._id] && attendance[user._id][dateKey] ? (
                        <Link
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#attendance_info"
                          onClick={() =>
                            handleShowAttendance(user._id, dateKey)
                          }
                        >
                          <i className="fa fa-check text-success" />
                        </Link>
                      ) : (
                        <i className="fa fa-close text-danger" />
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination-controls d-flex justify-content-end mt-4 align-items-end">
        {Array.from({ length: totalPages }, (_, i) => (
          <div className="mx-1">
            <button
              key={i + 1}
              className={i + 1 === currentPage ? "active" : ""}
              id="pagination-number"
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </button>
          </div>
        ))}
        <Select
          value={rowsPerPageOptions.find(
            (option) => option.value === rowsPerPage
          )}
          onChange={changeRowsPerPage}
          options={rowsPerPageOptions}
          className="rows-per-page-select mt-3 ms-3"
        />
      </div>

      <AttendenceModelPopup attendanceData={selectedAttendance} />
    </>
  );
};

export default TableAvatar;
