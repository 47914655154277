import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import AddDesingnationModelPopup from "../../../components/modelpopup/AddDesingnationModelPopup";
import SearchBox from "../../../components/SearchBox";
import useFetch from "../../../hooks/api/fetchHook";
import { useSelector, useDispatch } from "react-redux";
import Error404 from "../../../hooks/utils/Error/Error";
import Loading from "../../../hooks/utils/Loading/Loading";
import {
  updateDesignations,
  deleteDesignations,
} from "../../../store/slices/EmployeeSlice";
import UsePostData from "../../../hooks/api/PostHook";

const Designation = () => {
  const [users, setUsers] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [mode, setMode] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { userToken } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  // Fetch data using the custom useFetch hook
  const { apiData, isError, isLoading } = useFetch(
    "https://zolara-crm-backend.vercel.app/api/designations",
    options,
    refreshData
  );

  // Populate the users state with the fetched data
  useEffect(() => {
    if (apiData?.data?.designations) {
      setUsers(apiData.data.designations);
    }
  }, [apiData]);

  // Map the fetched users to the format required by the Table
  const userElements = users?.map((user, index) => ({
    key: index,
    id: index + 1,
    _id: user?._id,
    department: user?.department?.name,
    description: user.description,
    title: user.title,
  }));

  // Define the table columns
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      width: "10%",
    },
    {
      title: "Designation",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: "40%",
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.localeCompare(b.department),
      width: "40%",
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: "40%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#edit_designation"
              onClick={() => handleEditDesignation(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteDesignation(record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const { postData } = UsePostData(
    "https://zolara-crm-backend.vercel.app/api/designations"
  );

  const handleAddDepartment = async (designationData) => {
    const { title, description, department } = designationData;

    const requestBody = {
      title,
      description,
      department,
    };

    const result = await postData(requestBody);
    if (result.status === "success") {
      console.log("Department added successfully");
    } else {
      console.error("Failed to add department:", result.message);
    }
  };

  const handleEditDesignation = (designation) => {
    setSelectedDesignation(designation);
    setMode("edit");
  };
  console.log("selectedDesignation>>>>>", selectedDesignation);
  // Update designation
  const handleUpdateDesignation = async (designationData) => {
    const { title, description, department } = designationData;

    if (selectedDesignation) {
      await dispatch(
        updateDesignations({
          id: selectedDesignation._id,
          title,
          description,
        })
      );
      setSelectedDesignation(null);
      setMode("edit");
    }
  };

  // Handle delete designation action
  const handleDeleteDesignation = (designation) => {
    setSelectedDesignation(designation);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (selectedDesignation) {
      dispatch(deleteDesignations(selectedDesignation._id))
        .then(() => {
          setShowDeleteModal(false);
          setRefreshData((prev) => !prev); // Toggle refreshData to refetch API
        })
        .catch((error) => {
          console.error("Failed to delete department:", error);
        });
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Designations"
            title="Dashboard"
            subtitle="Designations"
            modal="#add_designation"
            name="Add Designation"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={userElements}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  locale={{
                    emptyText: isLoading ? (
                      <div>
                        <Loading /> {/* Shows loading spinner in table */}
                      </div>
                    ) : isError ? (
                      <div className="text-center text-danger">
                        <Error404 />
                      </div>
                    ) : (
                      "No Data"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div onClick={() => setMode("add")}>
        <AddDesingnationModelPopup
          onSubmit={handleAddDepartment}
          onEdit={handleUpdateDesignation}
          editDesignation={selectedDesignation}
          mode={mode}
        />
      </div>
      <DeleteModal
        Name="Delete Designation"
        closeModal={closeDeleteModal}
        onDeleteLeave={handleDelete}
      />
    </div>
  );
};

export default Designation;
