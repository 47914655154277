// src/components/EmployeeListFilter.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchDepartments } from "../store/slices/EmployeeSlice";
import { Link } from "react-router-dom";

const EmployeeListFilter = ({ onFilterChange }) => {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state?.employee?.departments);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused1, setFocused1] = useState(false);

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  const handleFilterChange = (newDepartment = selectedDepartment) => {
    onFilterChange({
      employeeId,
      employeeName,
      department: newDepartment,
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmployeeId(value);
    handleFilterChange();
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setEmployeeName(value);
    handleFilterChange();
  };

  const handleDepartmentChange = (option) => {
    setSelectedDepartment(option);
    handleFilterChange(option);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <div>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focused || employeeId !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={employeeId}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(employeeId !== "")}
              onChange={handleInputChange}
            />
            <label className="focus-label" onClick={() => setFocused(true)}>
              Employee ID
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focused1 || employeeName !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={employeeName}
              onFocus={() => setFocused1(true)}
              onBlur={() => setFocused1(employeeName !== "")}
              onChange={handleInputChange2}
            />
            <label className="focus-label" onClick={() => setFocused1(true)}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={[
                { value: null, label: "All" }, // All option to show employees from all departments
                ...departments.map((dept) => ({
                  value: dept._id,
                  label: dept.name,
                })),
              ]}
              placeholder="Select Department"
              styles={customStyles}
              onChange={handleDepartmentChange}
              value={selectedDepartment}
            />
            <label className="focus-label">Department</label>
          </div>
        </div>

        <div className="col-sm-6 col-md-3">
          <Link className="btn btn-success btn-block w-100">Search</Link>
        </div>
      </div>
    </div>
  );
};

export default EmployeeListFilter;
