// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async action to fetch user details with authentication token
export const fetchUserDetails = createAsyncThunk(
  "user/fetchUserDetails",
  async (userId, thunkAPI) => {
    console.log("Inside fetchUserDetails thunk");
    try {
      // Get the auth token from the state
      const state = thunkAPI.getState();
      const token = state.auth.userToken;

      // Set up headers with the auth token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Make the API request with the token
      const response = await axios.get(
        `https://zolara-crm-backend.vercel.app/api/users/${userId}`,
        config // Pass the config with headers
      );

      console.log("API call successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch user details:", error); // Error logging
      return thunkAPI.rejectWithValue(error.message); // Proper error handling
    }
  }
);

console.log(fetchUserDetails);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetails: {}, // Holds user details fetched from the API
    status: "idle", // Status to manage loading, succeeded, or failed states
    error: null, // Stores any error messages
  },
  reducers: {
    clearUserDetails(state) {
      state.userDetails = {}; // Clear user details
      state.status = "idle"; // Reset status to idle
      state.error = null; // Clear any existing errors
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = "loading"; // Set status to loading when fetching begins
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.status = "succeeded"; // Set status to succeeded upon successful fetch
        state.userDetails = action.payload; // Store fetched user details
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.status = "failed"; // Set status to failed if fetch fails
        state.error = action.payload || action.error.message; // Store error message
      });
  },
});

export const { clearUserDetails } = userSlice.actions; // Export actions for use in components
export default userSlice.reducer; // Export the reducer to be added to the store
